import React from 'react';

import {
  type OptionsObject,
  type ProviderContext,
  useSnackbar,
} from 'notistack';

let useSnackbarRef: ProviderContext;
export const SnackbarUtilsConfigurator: React.FC = () => {
  useSnackbarRef = useSnackbar();
  return null;
};

export default {
  success(msg: string) {
    this.toast(msg, { variant: `success` });
  },
  warning(msg: string) {
    this.toast(msg, { variant: `warning` });
  },
  info(msg: string) {
    this.toast(msg, { variant: `info` });
  },
  error(msg: string) {
    this.toast(msg, { variant: `error` });
  },
  toast(msg: string, options?: OptionsObject | undefined) {
    useSnackbarRef.enqueueSnackbar(msg, options);
  },
};
