import { createApi } from '@reduxjs/toolkit/dist/query/react';

import { paths } from '@/types/schema';
import {
  ExecuteReqwardRequestType,
  ExecuteReqwardResponseType,
  GetRewardDataResponseType,
  GetRewardProdcutsListResponseType,
  RewardsTransactionRequestType,
  RewardsTransactionResponseType,
} from '@/types/Services';

import { axiosBaseQuery, AxiosBaseQueryArgsType } from './axios/axiosBaseQuery';

import { transformResponse } from './utils';

export type GetScratchCardResponseType =
  paths['/rewards/scratch-card-info/{id}']['post']['responses']['200']['schema'];
export type GetScratchCardRequestType =
  paths['/rewards/scratch-card-info/{id}']['post']['parameters']['path']['id'];

export const rewardsBaseApi = createApi({
  reducerPath: `rewardsBaseApi`,
  baseQuery: axiosBaseQuery({ baseUrl: `/api/geebeeRoute?path=/rewards` }),
  endpoints: () => ({}),
});

export const rewardsBaseApiWithTags = rewardsBaseApi.enhanceEndpoints({
  addTagTypes: [`Rewards`],
});

export const rewardsApi = rewardsBaseApiWithTags.injectEndpoints({
  endpoints: (builder) => ({
    getRewardsProductsList: builder.query<
      GetRewardProdcutsListResponseType,
      void
    >({
      query: () => `/order/products/list`,
      transformResponse,
    }),
    getRewardsData: builder.query<GetRewardDataResponseType, void>({
      query: () => `/`,
      transformResponse,
      providesTags: [`Rewards`],
      keepUnusedDataFor: 0,
    }),
    placeOrder: builder.mutation<
      ExecuteReqwardResponseType,
      ExecuteReqwardRequestType
    >({
      query: (body) => {
        return {
          url: `/order/execute`,
          method: `POST`,
          body,
        } as AxiosBaseQueryArgsType;
      },
      transformResponse,
      // invalidatesTags: ['Rewards'],
    }),
    getCoinsTransactions: builder.mutation<
      RewardsTransactionResponseType,
      RewardsTransactionRequestType
    >({
      query: (body) => {
        return {
          url: `/transactions`,
          method: `POST`,
          body,
        } as AxiosBaseQueryArgsType;
      },
      transformResponse,
    }),
    getScratchCard: builder.mutation<
      GetScratchCardResponseType,
      GetScratchCardRequestType
    >({
      query: (id) => {
        return {
          url: `/scratch-card-info/${id}`,
          method: `POST`,
        };
      },
      transformResponse,
    }),
  }),
});

export const {
  useGetRewardsProductsListQuery,
  useGetRewardsDataQuery,
  usePlaceOrderMutation,
  useGetCoinsTransactionsMutation,
  useGetScratchCardMutation,
} = rewardsApi;

export const useGetRewardsProductsListQueryState =
  rewardsApi.endpoints.getRewardsProductsList.useQueryState;
