import { Close } from '@mui/icons-material';
import { IconButton } from '@mui/material';
import classNames from 'classnames';

import { MODAL_TYPES } from '@/components/Modals/GlobalModal';
import useModal from '@/components/Modals/useModal';
import { localStorageKeys } from '@/utils/GBLocalStorageUtil';
import { markModalShown } from '@/utils/newsletterFeatureUtil';

type Props = {
  redirectionUrl: string;
  imageUrl: string;
  storageKey: localStorageKeys;
  hideImageClick?: boolean;
  imageClassName?: string;
  containerClassName?: string;
  imageContainerClassName?: string;
  onImageClick?: () => void;
  onModalClose?: () => void;
  closeButtonClassName?: string;
  closeModalOnSubmit?: boolean;
};
const LoanMelaAwarenessPopper = ({
  redirectionUrl,
  imageUrl,
  storageKey,
  hideImageClick,
  imageClassName,
  containerClassName,
  onImageClick,
  imageContainerClassName,
  onModalClose,
  closeButtonClassName,
  closeModalOnSubmit,
}: Props) => {
  const { hideModal } = useModal();

  const markModalShownAndClose = () => {
    markModalShown(storageKey);
    hideModal(MODAL_TYPES.NO_RESULTS);
    onModalClose?.();
  };

  return (
    <div className="flex justify-center items-center w-full h-full">
      <div
        className={classNames(
          `w-[50%] h-full flex items-center justify-center relative`,
          containerClassName,
        )}
      >
        <div
          className={classNames(
            `h-[80%] max-h-[80%] overflow-y-auto w-full`,
            imageContainerClassName,
          )}
        >
          {/* eslint-disable-next-line @next/next/no-img-element */}
          <img
            src={imageUrl}
            alt="representation"
            onClick={() => {
              if (hideImageClick) return;
              window.open(redirectionUrl, `_blank`);
              onImageClick?.();
              if (closeModalOnSubmit) {
                markModalShownAndClose();
              }
            }}
            className={classNames(`cursor-pointer`, imageClassName)}
          />
        </div>
        <IconButton
          className={classNames(
            `absolute top-[8%] -right-3 bg-white p-1`,
            closeButtonClassName,
          )}
          onClick={markModalShownAndClose}
        >
          <Close className="text-black" />
        </IconButton>
      </div>
    </div>
  );
};

export default LoanMelaAwarenessPopper;
