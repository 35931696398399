import React, { Ref } from 'react';

import { TextGroupAlert } from '@leapfinance/geebee-component-library';
import { type CustomContentProps, SnackbarContent } from 'notistack';

type InfoSnackBarProps = CustomContentProps;

const InfoSnackBar = React.forwardRef(
  (props: InfoSnackBarProps, ref: Ref<HTMLDivElement>) => {
    const { message } = props;
    return (
      <SnackbarContent ref={ref} {...{ ...props, id: String(props.id) }}>
        <TextGroupAlert title={message} variant="primary" />
      </SnackbarContent>
    );
  },
);

InfoSnackBar.displayName = `InfoSnackBar`;
export default InfoSnackBar;
