export type BaseResponse<Data> = {
  success: boolean;
  data: Data;
  message: string;
};

export type BFFApiError<T> = {
  success: boolean;
  data: T;
  message: string;
  errorCode: string | undefined;
};

export const transformResponse = <Req extends BaseResponse<Req>, Res>(
  data: Req,
) => {
  if (!data.success) {
    return {
      data: null,
      message: data.message,
      apiError: true,
    } as unknown as Res;
  } else {
    return data as unknown as Res;
  }
};
