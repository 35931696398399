import { INRCurrencyDetails } from '@/components/CF/Dashbaord/helpers';
import { AxiosBaseQueryArgsType } from '@/services/axios/axiosBaseQuery';
import { geebeeRootBaseApiWithTags } from '@/services/base/geebeeRoute';
import { transformResponse } from '@/services/utils';
import { paths } from '@/types/schema';
import {
  BulkAddToShortlistRequestType,
  BulkAddToShortlistResponseType,
  CountryCurrenciesResponseType,
  GetCfCourseRequestType,
  GetCfCourseResponseType,
  GetCourseDetailsV2ResponseType,
  GetCoursesForFocusedInstitutesRequestType,
  GetCoursesForFocusedInstitutesResponseType,
  GetFilterValuesRequestType,
  GetFilterValuesResponseType,
  GetFocusedUniversityCoursesForApplicationIdRequestType,
  GetFocusedUniversityCoursesForApplicationIdResponseType,
  GetKeyworkSuggestionsRequestType,
  GetKeyworkSuggestionsResponseType,
  GetLinearFilterValuesRequestType,
  GetLinearFilterValuesResponseType,
  GetStudyDestinationSuggestionRequestType,
  GetStudyDestinationSuggestionResponseType,
  GetSuggestionsDataPathRequestType,
  GetSuggestionsDataPathResponseType,
  NoResultInfoRequestType,
  NoResultInfoResponseType,
  SourceCountriesResponseType,
} from '@/types/Services';

export type GerUniversityQuickFilterResponseType =
  paths['/cf/aggregations/university-quick-filter']['get']['responses']['200']['schema'];
export type GetAutoShortlistRecommendedCoursesRequestType =
  paths['/cf/recommended/courses/system']['post']['parameters']['body']['dto'];
export type GetAutoShortlistRecommendedCoursesResponseType =
  paths['/cf/recommended/courses/system']['post']['responses']['200']['schema'];

export const geebeeCfApi = geebeeRootBaseApiWithTags.injectEndpoints({
  endpoints: (builder) => ({
    getSuggestionsDataV2: builder.mutation<
      GetSuggestionsDataPathResponseType,
      GetSuggestionsDataPathRequestType
    >({
      query: (payload) => {
        const request: AxiosBaseQueryArgsType = {
          url: `/cf`,
          body: payload[`courseFinderRequestDto`],
          method: `POST`,
          params: {
            sameSpecialisation: payload.sameSpecialisation,
            sameSubStream: payload.sameSubStream,
            aggregation: payload.aggregation,
          },
        };

        return request;
      },
      transformResponse: transformResponse,
    }),
    getCFKeywordSuggestions: builder.mutation<
      GetKeyworkSuggestionsResponseType,
      GetKeyworkSuggestionsRequestType
    >({
      query: (payload) => {
        const request: AxiosBaseQueryArgsType = {
          url: `/cf/keywords/suggestion`,
          body: payload,
          method: `POST`,
        };

        return request;
      },
      transformResponse: ({ success, data, message }) => {
        if (!success) {
          return {
            data: null,
            message,
            apiError: true,
          } as unknown as GetKeyworkSuggestionsResponseType;
        } else {
          return {
            success,
            data,
            message,
          } as unknown as GetKeyworkSuggestionsResponseType;
        }
      },
    }),
    getStudyDestinationSuggestions: builder.mutation<
      GetStudyDestinationSuggestionResponseType,
      GetStudyDestinationSuggestionRequestType
    >({
      query: (payload) => {
        const request: AxiosBaseQueryArgsType = {
          url: `/cf/study-destination/suggestion`,
          body: payload,
          method: `POST`,
        };

        return request;
      },
      transformResponse: ({ success, data, message }) => {
        if (!success) {
          return {
            data: null,
            message,
            apiError: true,
          } as unknown as GetStudyDestinationSuggestionResponseType;
        } else {
          return {
            success,
            data,
            message,
          } as unknown as GetStudyDestinationSuggestionResponseType;
        }
      },
    }),
    bulkAddToShortlist: builder.mutation<
      BulkAddToShortlistResponseType,
      BulkAddToShortlistRequestType
    >({
      query: ({ studentId, courses }) => {
        const request: AxiosBaseQueryArgsType = {
          url: `/v1/shortlist/student/${studentId}/bulk`,
          body: courses,
          method: `POST`,
        };

        return request;
      },
      transformResponse: ({ success, data, message }) => {
        if (!success) {
          return {
            data: null,
            message,
            apiError: true,
          } as unknown as BulkAddToShortlistResponseType;
        } else {
          return {
            success,
            data,
            message,
          } as unknown as BulkAddToShortlistResponseType;
        }
      },
      invalidatesTags: [`ShortlistRequest`],
    }),
    getCountryCurrencies: builder.mutation<
      CountryCurrenciesResponseType,
      { sourceCountryId: number }
    >({
      query: (body) => {
        const request: AxiosBaseQueryArgsType = {
          url: `/cf/currencies/view-all`,
          body,
          method: `POST`,
        };

        return request;
      },
      transformResponse: ({ success, data, message }) => {
        if (!success) {
          return {
            data: {
              currencies: INRCurrencyDetails,
              minValueInr: 0,
              maxValueInr: 20000000,
            },
            message,
            apiError: true,
          } as unknown as CountryCurrenciesResponseType;
        } else {
          return {
            success,
            data: {
              ...data,
              currencies: data?.currencies ?? INRCurrencyDetails,
              minValueInr: data?.minValueInr ?? 0,
              maxValueInr: data?.maxValueInr ?? 20000000,
            },
            message,
          } as unknown as CountryCurrenciesResponseType;
        }
      },
    }),
    getCourseDetails: builder.query<
      GetCfCourseResponseType,
      GetCfCourseRequestType
    >({
      query: (payload) => {
        const request: AxiosBaseQueryArgsType = {
          url: `/cf/${payload.courseId}${
            payload.studentId ? `?studentId=${payload.studentId}` : ``
          }`,
        };

        return request;
      },
      transformResponse: ({ success, data, message }) => {
        if (!success) {
          return {
            data: null,
            message,
            apiError: true,
          } as unknown as GetCfCourseResponseType;
        } else {
          return {
            success,
            data,
            message,
          } as unknown as GetCfCourseResponseType;
        }
      },
    }),
    getSourceCountries: builder.query<SourceCountriesResponseType, void>({
      query: () => ({ url: `/cf/source-countries` }),
      transformResponse: ({ success, data, message }) => {
        if (!success) {
          return {
            data: null,
            message,
            apiError: true,
          } as unknown as SourceCountriesResponseType;
        } else {
          return {
            success,
            data,
            message,
          } as unknown as SourceCountriesResponseType;
        }
      },
    }),
    getFilterValuesCF: builder.mutation<
      GetFilterValuesResponseType,
      GetFilterValuesRequestType
    >({
      query: (body) => {
        const request: AxiosBaseQueryArgsType = {
          url: `/cf/filter-values`,
          body,
          method: `POST`,
        };

        return request;
      },
      transformResponse: ({ success, data, message }) => {
        if (!success) {
          return {
            data: null,
            message,
            apiError: true,
          } as unknown as GetFilterValuesResponseType;
        } else {
          return {
            success,
            data,
            message,
          } as unknown as GetFilterValuesResponseType;
        }
      },
    }),
    getEligibleCourses: builder.mutation<
      any,
      { applicationId: number; pageNumber: number; pageSize: number }
    >({
      query: (body) => {
        const request: AxiosBaseQueryArgsType = {
          url: `cf/recommended-courses`,
          body,
          method: `POST`,
        };
        return request;
      },
      transformResponse: ({ success, data, message }) => {
        if (!success) {
          return {
            data: null,
            message,
            apiError: true,
          } as unknown as any;
        } else {
          return {
            success,
            data,
            message,
          } as unknown as any;
        }
      },
    }),
    getLinearFilterValues: builder.mutation<
      GetLinearFilterValuesResponseType,
      GetLinearFilterValuesRequestType
    >({
      query: (body) => {
        const request: AxiosBaseQueryArgsType = {
          url: `/cf/linear-filter-values`,
          body,
          method: `POST`,
        };

        return request;
      },
      transformResponse: transformResponse,
    }),
    getNoResultInfo: builder.mutation<
      NoResultInfoResponseType,
      NoResultInfoRequestType
    >({
      query: (body) => {
        const request: AxiosBaseQueryArgsType = {
          url: `/cf/no-result-info`,
          body,
          method: `POST`,
        };

        return request;
      },
      transformResponse: transformResponse,
    }),
    getCourseDetailsV2: builder.query<
      GetCourseDetailsV2ResponseType,
      { courseId: number }
    >({
      query: ({ courseId }) => `/cf/v2/course/${courseId}`,
      transformResponse,
    }),
    getFocusedInstitutesCourses: builder.mutation<
      GetCoursesForFocusedInstitutesResponseType,
      GetCoursesForFocusedInstitutesRequestType
    >({
      query: (body) => {
        const request: any = {
          url: `/cf/university/courses/multiple`,
          body,
          method: `POST`,
        };

        return request;
      },
      transformResponse: transformResponse,
    }),
    getFocusedUniversityCoursesForApplicationId: builder.query<
      GetFocusedUniversityCoursesForApplicationIdResponseType,
      GetFocusedUniversityCoursesForApplicationIdRequestType
    >({
      query: ({ applicationId }) =>
        `/cf/focused-universities/courses/${applicationId}`,
      transformResponse: transformResponse,
    }),
    getAutoShortlistRecommendedCourses: builder.mutation<
      GetAutoShortlistRecommendedCoursesResponseType,
      GetAutoShortlistRecommendedCoursesRequestType
    >({
      query: (body) => {
        return {
          method: `POST`,
          url: `/cf/recommended/courses/system`,
          body,
        };
      },
      transformResponse,
    }),
    getUniversityQuickFilter: builder.query<
      GerUniversityQuickFilterResponseType,
      void
    >({
      query: () => `/cf/aggregations/university-quick-filter`,
      transformResponse: transformResponse,
    }),
  }),
});

export const {
  useGetSuggestionsDataV2Mutation,
  useGetCFKeywordSuggestionsMutation,
  useGetStudyDestinationSuggestionsMutation,
  useBulkAddToShortlistMutation,
  useGetCountryCurrenciesMutation,
  useGetSourceCountriesQuery,
  useLazyGetCourseDetailsQuery,
  useGetEligibleCoursesMutation,
  useGetFilterValuesCFMutation,
  useGetLinearFilterValuesMutation,
  useGetNoResultInfoMutation,
  useGetCourseDetailsV2Query,
  useLazyGetCourseDetailsV2Query,
  useGetFocusedInstitutesCoursesMutation,
  useGetFocusedUniversityCoursesForApplicationIdQuery,
  useLazyGetFocusedUniversityCoursesForApplicationIdQuery,
  useGetUniversityQuickFilterQuery,
  useGetAutoShortlistRecommendedCoursesMutation,
} = geebeeCfApi;
