import { useEffect, useState } from 'react';
import { Provider } from 'react-redux';

import { ThemeProvider } from '@mui/system';
import { AppProps } from 'next/app';
import Head from 'next/head';
import { useRouter } from 'next/router';

import { store } from '@/app/store';
import { ConfirmDialog } from '@/components';
import { isFalsy } from '@/components/CF/Dashbaord/helpers';
import ProfileProvider from '@/components/context/ProfileDetails';
import FileUploadv2 from '@/components/FileUploadv2';
import HeadScripts from '@/components/HeadScripts';
import JoyrideComponent from '@/components/JoyrideComponent';
import Layout from '@/components/Layout';
import GlobalModal from '@/components/Modals/GlobalModal';
import TokenValidation from '@/components/TokenValidation';
import { WebCamProvider } from '@/components/WebCamProvider';
import theme from '@/material-theme';
import { Permissions } from '@/permissions/featuresList';
import SnackBarProvider from '@/providers/SnackBarProvider';
import { trackPage } from '@/segment/eventTracker';
import { authenticationExemptedRoutes } from '@/utils/constants';
import { SnackbarUtilsConfigurator } from '@/utils/snackbar';

import './styles.css';
import '../styles/global.css';
import '@leapfinance/frontend-commons/tailwind-compiled.css';

export default function MyApp({ Component, pageProps }: AppProps) {
  const apiUrl = process.env.AUTH_SERVER;
  const isDev = apiUrl === `https://staging-auth.leapscholar.com`;

  const [currentPageRoute, setCurrentPageRoute] = useState<string>();

  const router = useRouter();

  const isStudentUser =
    store.getState().user?.userConfig?.permissions?.[0] === Permissions.STUDENT;

  useEffect(() => {
    setCurrentPageRoute(router?.pathname?.toString());
  }, [router]);

  useEffect(() => {
    if (
      currentPageRoute &&
      authenticationExemptedRoutes?.includes(currentPageRoute)
    )
      return;
    trackPage({
      pageName: Component.displayName || `Unkown Page`,
    });
  }, [Component.displayName]);

  if (typeof window === `undefined` || isFalsy(currentPageRoute)) {
    return <></>;
  }

  if (isStudentUser && !router?.route?.startsWith(`/test-prep/`)) {
    router?.replace(`/test-prep/mock-test`);
    return null;
  }

  if (
    currentPageRoute &&
    authenticationExemptedRoutes?.includes(currentPageRoute)
  ) {
    return (
      <Provider store={store}>
        <ThemeProvider theme={theme}>
          <SnackBarProvider>
            <WebCamProvider>
              <Head>
                {isDev ? (
                  <meta name="robots" content="noindex, nofollow" />
                ) : null}
                <link rel="icon" href="/GBSign.svg" />
                <title>CSP Portal</title>
                <link rel="preconnect" href="https://fonts.googleapis.com" />
                <link
                  rel="preconnect"
                  href="https://fonts.gstatic.com"
                  crossOrigin="true"
                />
                {/* eslint-disable-next-line @next/next/no-page-custom-font */}
                <link
                  href="https://fonts.googleapis.com/css2?family=Noto+Sans:ital,wght@0,100..900;1,100..900&family=Poppins:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap"
                  rel="stylesheet"
                />
              </Head>
              <Component {...pageProps} />
              <GlobalModal />
              <SnackbarUtilsConfigurator />
            </WebCamProvider>
          </SnackBarProvider>
        </ThemeProvider>
      </Provider>
    );
  }

  return (
    <Provider store={store}>
      <TokenValidation>
        <WebCamProvider>
          <ThemeProvider theme={theme}>
            <ProfileProvider>
              <SnackBarProvider>
                <>
                  <HeadScripts>
                    {authenticationExemptedRoutes?.includes(
                      router?.pathname?.toString(),
                    ) ? (
                      <Component {...pageProps} />
                    ) : (
                      <Layout>
                        <Component {...pageProps} />
                      </Layout>
                    )}
                  </HeadScripts>
                  <ConfirmDialog />
                  <FileUploadv2 />
                  <GlobalModal />
                  <SnackbarUtilsConfigurator />
                  {/* <ProductTour /> */}
                  <JoyrideComponent />
                  {/* <div className="py-8"></div> */}
                </>
              </SnackBarProvider>
            </ProfileProvider>
          </ThemeProvider>
        </WebCamProvider>
      </TokenValidation>
    </Provider>
  );
}
