import { createApi } from '@reduxjs/toolkit/query/react';

import { axiosBaseQuery } from '../axios/axiosBaseQuery';

export const cmsBaseApi = createApi({
  reducerPath: `cmsBaseApi`,
  baseQuery: axiosBaseQuery({
    baseUrl: process.env.NEXT_PUBLIC_CMS_URL as string,
  }),
  endpoints: () => ({}),
});

export const cmsBaseApiWithTags = cmsBaseApi.enhanceEndpoints({
  addTagTypes: [`PillarPages`],
});
