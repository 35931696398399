import { createSlice, type PayloadAction } from '@reduxjs/toolkit';

import { AppThunk } from '@/app/store';

export type MediaPermission = { camera: PermissionState; mic: PermissionState };

// Define the state interface
interface WebCamState {
  videoBrightness: number;
  audioLevel: number;
  mediaStream: MediaStream | null;
  mediaPermission: MediaPermission | null;
  recordedMedia: Blob | null;
  recordedAudio: Blob | null;
  isRecording: boolean;
}

// Initial state
const initialState: WebCamState = {
  videoBrightness: 0,
  audioLevel: 0,
  mediaStream: null,
  mediaPermission: null,
  recordedMedia: null,
  recordedAudio: null,
  isRecording: false,
};

// Create the webcam slice
export const webcamSlice = createSlice({
  name: `webCam`,
  initialState,
  reducers: {
    setVideoBrightness: (state, action: PayloadAction<number>) => {
      state.videoBrightness = action.payload;
    },
    setAudioLevel: (state, action: PayloadAction<number>) => {
      state.audioLevel = action.payload;
    },
    setMediaStream: (state, action: PayloadAction<MediaStream | null>) => {
      state.mediaStream = action.payload;
    },
    setMediaPermission: (
      state,
      action: PayloadAction<MediaPermission | null>,
    ) => {
      state.mediaPermission = action.payload;
    },
    setRecordedMedia: (state, action: PayloadAction<Blob | null>) => {
      state.recordedMedia = action.payload;
    },
    setRecordedAudio: (state, action: PayloadAction<Blob | null>) => {
      state.recordedAudio = action.payload;
    },
    setIsRecording: (state, action: PayloadAction<boolean>) => {
      state.isRecording = action.payload;
    },
    resetWebcamState: (state) => {
      state.videoBrightness = 0;
      state.audioLevel = 0;
      state.mediaStream = null;
      state.isRecording = false;
      // not resetting recorded media to preserve recordings
    },
  },
});

// Export actions
export const {
  setVideoBrightness,
  setAudioLevel,
  setMediaStream,
  setMediaPermission,
  setRecordedMedia,
  setRecordedAudio,
  setIsRecording,
  resetWebcamState,
} = webcamSlice.actions;

// Thunk actions for async operations
export const checkCameraAndMicPermissions =
  (): AppThunk => async (dispatch) => {
    if (navigator.permissions && navigator.permissions.query) {
      try {
        const cameraPermission = await navigator.permissions.query({
          name: `camera` as PermissionName,
        });

        const micPermission = await navigator.permissions.query({
          name: `microphone` as PermissionName,
        });

        dispatch(
          setMediaPermission({
            camera: cameraPermission.state,
            mic: micPermission.state,
          }),
        );
      } catch (error) {
        console.error(`Error checking camera permission:`, error);
      }
    } else {
      try {
        const stream = await navigator.mediaDevices.getUserMedia({
          video: true,
          audio: true,
        });

        // If getUserMedia succeeds, permissions are granted
        stream.getTracks().forEach((track) => track.stop()); // Stop the stream

        dispatch(
          setMediaPermission({
            camera: `granted`,
            mic: `granted`,
          }),
        );
      } catch (error) {
        console.error(`Error accessing media:`, error);
        dispatch(
          setMediaPermission({
            camera: `denied`,
            mic: `denied`,
          }),
        );
      }
    }
  };

export const startCameraStream =
  (
    videoConstrains?: MediaTrackConstraintSet,
    audioConstraints?: MediaTrackConstraintSet,
  ): AppThunk =>
  async (dispatch) => {
    try {
      const getConstraintsIfAvailable = (
        constraints: MediaTrackConstraintSet,
      ): MediaTrackConstraintSet => {
        const availableConstraints =
          navigator.mediaDevices.getSupportedConstraints();
        return Object.keys(constraints)
          .filter(
            (constraint) =>
              availableConstraints?.[
                constraint as keyof MediaTrackSupportedConstraints
              ],
          )
          .reduce((prev, curr) => {
            return {
              ...prev,
              [curr]: constraints[curr as keyof MediaTrackSupportedConstraints],
            };
          }, {});
      };

      const videoConstraints =
        typeof videoConstrains !== `undefined`
          ? getConstraintsIfAvailable(videoConstrains)
          : true;

      const constraints = {
        video: videoConstraints,
        audio:
          typeof audioConstraints !== `undefined`
            ? getConstraintsIfAvailable(audioConstraints)
            : true,
      };

      const stream = await navigator.mediaDevices.getUserMedia(constraints);
      dispatch(setMediaStream(stream));

      // Also update permissions after starting stream
      dispatch(checkCameraAndMicPermissions());
    } catch (error) {
      console.error(`Error accessing camera:`, error);
      dispatch(checkCameraAndMicPermissions());
    }
  };

export const stopCameraStream = (): AppThunk => (dispatch, getState) => {
  try {
    const { mediaStream } = getState().webCam;

    mediaStream?.getTracks().forEach((track) => {
      track.stop(); // Stop each track
    });

    dispatch(setMediaStream(null));
  } catch (error) {
    console.error(`Error stopping camera:`, error);
  }
};

export default webcamSlice;
