import { createSlice, type PayloadAction } from '@reduxjs/toolkit';

import { RootState } from '@/app/store';
import { definitions } from '@/types/schema';

type ShortlistDetailsStateType = {
  recommendedCourses: definitions['CourseFinderCourseCardDto'][];
  selectedCourses: definitions['CourseFinderCourseCardDto'][];
  shortlistedCourses: definitions['CourseFinderCourseCardDto'][];
};

const initialState: ShortlistDetailsStateType = {
  recommendedCourses: [],
  selectedCourses: [],
  shortlistedCourses: [],
};

export const shortlistDetailsSlice = createSlice({
  name: `shortlistDetailsSlice`,
  initialState,
  reducers: {
    updateRecommendedCourses: (
      state,
      action: PayloadAction<definitions['CourseFinderCourseCardDto'][]>,
    ) => {
      state.recommendedCourses = action.payload;
    },
    updateSelectedCourses: (
      state,
      action: PayloadAction<definitions['CourseFinderCourseCardDto'][]>,
    ) => {
      state.selectedCourses = action.payload;
    },
    updateShortlistedCourses: (
      state,
      action: PayloadAction<definitions['CourseFinderCourseCardDto'][]>,
    ) => {
      state.shortlistedCourses = action.payload;
    },
  },
});

export const {
  updateRecommendedCourses,
  updateSelectedCourses,
  updateShortlistedCourses,
} = shortlistDetailsSlice.actions;

export const selectAutoShortlistRecommendedCourses = (state: RootState) =>
  state?.shortlistDetailsSlice?.recommendedCourses;

export const selectCoursesSelectedForShortlist = (state: RootState) =>
  state?.shortlistDetailsSlice?.selectedCourses;

export const selectCoursesShortlisted = (state: RootState) =>
  state?.shortlistDetailsSlice?.shortlistedCourses;

export default shortlistDetailsSlice.reducer;
