import React, {
  createContext,
  ReactNode,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { AppDispatch, RootState } from '@/app/store';
import {
  checkCameraAndMicPermissions,
  MediaPermission,
  resetWebcamState,
  setAudioLevel,
  setIsRecording,
  setRecordedAudio,
  setRecordedMedia,
  setVideoBrightness,
  startCameraStream,
  stopCameraStream,
} from '@/features/webcam/webcam.slice';
import { audioMimeTypes, videoMimeTypes } from '@/hooks/webcam/utils';

type StartWebCamOptions = {
  checkForVideoDarkness?: boolean;
  checkForMicInput?: boolean;
  audioConstraints?: MediaTrackConstraintSet;
  videoConstrains?: MediaTrackConstraintSet;
};
interface WebCamContextProps {
  startWebcam: (options: StartWebCamOptions) => Promise<void>;
  stopWebcam: () => void;
  startWebCamAndAudioRecordingSeparately: () => void;
  cameraPermissions: MediaPermission | null;
  videoElementRef: React.RefObject<HTMLVideoElement>;
  cameraBrightness: number;
  microphoneLevel: number;
  recordedMedia: Blob | null;
  recordedAudio: Blob | null;
  stopRecording: (stopStream?: boolean) => void;
  isRecording: boolean;
  mediaStream: MediaStream | null;
}

interface WebCamProviderProps {
  children: ReactNode;
  onRecordedMediaDataAvailable?: (data: Blob) => void;
  onRecordedAudioDataAvailable?: (data: Blob) => void;
}

const WebCamContext = createContext<WebCamContextProps | undefined>(undefined);

export const WebCamProvider: React.FC<WebCamProviderProps> = ({
  children,
  onRecordedMediaDataAvailable,
  onRecordedAudioDataAvailable,
}) => {
  const dispatch = useDispatch<AppDispatch>();
  const videoRef = useRef<HTMLVideoElement>(null);
  const audioVideoMediaRecorderRef = useRef<MediaRecorder | null>(null);
  const audioMediaRecorderRef = useRef<MediaRecorder | null>(null);
  const audioRecordedChunksRef = useRef<Blob[]>([]);
  const audioVideoRecordedChunksRef = useRef<Blob[]>([]);

  const [checkForVideoDarkness, setCheckForVideoDarkness] =
    useState<boolean>(false);
  const [checkForMicInput, setCheckForMicInput] = useState<boolean>(false);

  // Select state from Redux store
  const {
    videoBrightness,
    audioLevel,
    mediaStream,
    mediaPermission,
    recordedMedia,
    recordedAudio,
    isRecording,
  } = useSelector((state: RootState) => state.webCam);

  useEffect(() => {
    let timerId: NodeJS.Timeout | undefined;
    if (mediaStream && !videoRef.current?.srcObject) {
      timerId = setTimeout(() => {
        if (videoRef.current) {
          videoRef.current.srcObject = mediaStream;
          videoRef.current.play();
        }
      }, 100);
    }

    if (!mediaPermission) {
      dispatch(resetWebcamState());
    }

    return () => {
      if (timerId) clearTimeout(timerId);
    };
  }, [mediaStream]);

  useEffect(() => {
    return () => {
      dispatch(stopCameraStream());
      dispatch(setIsRecording(false));
    };
  }, [dispatch]);

  useEffect(() => {
    let cancelFunction: (() => void) | undefined;

    setupLiveCheck(
      mediaStream,
      startLiveVideoQualityCheck,
      (brightness: number) => dispatch(setVideoBrightness(brightness)),
      checkForVideoDarkness,
    ).then((cleanup) => {
      cancelFunction = cleanup;
    });

    return () => {
      if (cancelFunction) {
        cancelFunction();
      }
    };
  }, [mediaStream, checkForVideoDarkness, dispatch]);

  useEffect(() => {
    let cancelFunction: (() => void) | undefined;

    setupLiveCheck(
      mediaStream,
      startLiveAudioLevelCheck,
      (level: number) => dispatch(setAudioLevel(level)),
      checkForMicInput,
    ).then((cleanup) => {
      cancelFunction = cleanup;
    });

    return () => {
      if (cancelFunction) {
        cancelFunction();
      }
    };
  }, [mediaStream, checkForMicInput, dispatch]);

  // Attach media stream to video element when it changes
  useEffect(() => {
    if (mediaStream && videoRef.current) {
      videoRef.current.srcObject = mediaStream;
      videoRef.current.play().catch((error) => {
        console.error(`Error playing video:`, error);
      });
    } else if (!mediaStream && videoRef.current) {
      videoRef.current.pause();
      videoRef.current.srcObject = null;
    }
  }, [mediaStream]);

  const setupLiveCheck = async <T = unknown,>(
    stream: MediaStream | null,
    checkFunction: (
      stream: MediaStream,
      callback: (value: T) => void,
    ) => Promise<() => void>,
    callback: (value: T) => void,
    enabled: boolean | undefined,
  ): Promise<(() => void) | undefined> => {
    if (stream && enabled) {
      return await checkFunction(stream, callback);
    }
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    return () => {};
  };

  const startLiveVideoQualityCheck = async (
    stream: MediaStream,
    callback: (videoBrightness: number) => void,
  ) => {
    try {
      const videoTracks = stream.getVideoTracks();
      if (videoTracks.length === 0) {
        console.warn(`No video tracks available.`);
        callback(0); // Video is not usable
        // eslint-disable-next-line @typescript-eslint/no-empty-function
        return () => {};
      }

      const videoTrack = videoTracks[0];
      const videoTrackSettings = videoTrack.getSettings();

      const canvas = document.createElement(`canvas`);
      //passing willReadFrequently for performance optimization: https://html.spec.whatwg.org/multipage/canvas.html#concept-canvas-will-read-frequently
      const context = canvas.getContext(`2d`, { willReadFrequently: true });
      const video = document.createElement(`video`);
      video.srcObject = new MediaStream([videoTrack]);
      video.muted = true;
      await video.play();

      canvas.width = videoTrackSettings.width || video.videoWidth;
      canvas.height = videoTrackSettings.height || video.videoHeight;

      let animationFrameId: number;

      const analyzeFrame = () => {
        if (!context) {
          console.error(`Canvas context not available.`);
          callback(0); // Video is not usable
          // eslint-disable-next-line @typescript-eslint/no-empty-function
          return () => {};
        }
        context.drawImage(video, 0, 0, canvas.width, canvas.height);
        const imageData = context.getImageData(
          0,
          0,
          canvas.width,
          canvas.height,
        ).data;
        let brightnessSum = 0;
        for (let i = 0; i < imageData.length; i += 4) {
          brightnessSum +=
            (imageData[i] + imageData[i + 1] + imageData[i + 2]) / 3;
        }
        const averageBrightness =
          brightnessSum / (canvas.width * canvas.height);

        callback(averageBrightness);

        animationFrameId = requestAnimationFrame(analyzeFrame);
      };

      animationFrameId = requestAnimationFrame(analyzeFrame);

      // Cleanup function to stop the live check
      return () => {
        cancelAnimationFrame(animationFrameId);
        video.pause();
        video.srcObject = null;
        canvas.remove();
        video.remove();
      };
    } catch (error) {
      console.error(`Error checking video quality:`, error);
      callback(0); // Video is not usable
      // eslint-disable-next-line @typescript-eslint/no-empty-function
      return () => {};
    }
  };

  const startLiveAudioLevelCheck = async (
    stream: MediaStream,
    audioLevelCallback: (audioLevel: number) => void,
  ) => {
    try {
      const audioTracks = stream.getAudioTracks();
      if (audioTracks.length === 0) {
        console.warn(`No audio tracks available.`);
        audioLevelCallback(0);
        // eslint-disable-next-line @typescript-eslint/no-empty-function
        return () => {};
      }

      const audioContext = new window.AudioContext();
      const analyser = audioContext.createAnalyser();
      analyser.fftSize = 256;
      const source = audioContext.createMediaStreamSource(stream);
      source.connect(analyser);
      const bufferLength = analyser.frequencyBinCount;
      const dataArray = new Uint8Array(bufferLength);

      let audioAnimationFrameId: number;

      const analyzeAudioFrame = () => {
        analyser.getByteTimeDomainData(dataArray);
        let sum = 0;
        for (let i = 0; i < dataArray.length; i++) {
          const normalizedValue = dataArray[i] / 128 - 1;
          const positiveValue = Math.abs(normalizedValue);
          sum += positiveValue;
        }
        const average = (sum / dataArray.length) * 200; // Linear scaling
        // const average = Math.log10((sum / dataArray.length) * 5000 + 1) * 20; // Logarithmic scaling

        audioLevelCallback(average);

        audioAnimationFrameId = requestAnimationFrame(analyzeAudioFrame);
      };

      audioAnimationFrameId = requestAnimationFrame(analyzeAudioFrame);

      // Cleanup function
      return () => {
        cancelAnimationFrame(audioAnimationFrameId);
        audioContext.close();
      };
    } catch (error) {
      console.error(`Error checking audio level:`, error);
      audioLevelCallback(0); // Return 0 if there's an error
      // eslint-disable-next-line @typescript-eslint/no-empty-function
      return () => {};
    }
  };

  const startWebcam = async (options: StartWebCamOptions) => {
    dispatch(checkCameraAndMicPermissions());
    setCheckForMicInput(!!options?.checkForMicInput);
    setCheckForVideoDarkness(!!options?.checkForVideoDarkness);
    dispatch(
      startCameraStream(options?.videoConstrains, options?.audioConstraints),
    );
  };

  const stopWebcam = () => {
    dispatch(stopCameraStream());
  };

  const startWebCamAndAudioRecordingSeparately = () => {
    const stream = videoRef.current?.srcObject as MediaStream;
    if (!stream) {
      console.error(
        `Error no camera stream found for recording, you need to start webcam before recording`,
      );
      return;
    }
    const audioTrack = stream.getAudioTracks()[0];
    const audioStream = new MediaStream([audioTrack]);
    if (!audioStream) {
      console.error(
        `Error no audio stream found for recording, you need to start webcam before recording`,
      );
      return;
    }
    const supportedVideoMimeType = videoMimeTypes.filter((mime: string) =>
      MediaRecorder.isTypeSupported(mime),
    )?.[0];
    const supportedAudioMimeType = audioMimeTypes.filter((mime: string) =>
      MediaRecorder.isTypeSupported(mime),
    )?.[0];

    if (!supportedAudioMimeType || !supportedVideoMimeType) {
      console.error(`No supported mime type for recording found`);
      return;
    }

    try {
      audioVideoMediaRecorderRef.current = new MediaRecorder(stream, {
        mimeType: supportedVideoMimeType,
      });

      audioMediaRecorderRef.current = new MediaRecorder(audioStream, {
        mimeType: supportedAudioMimeType,
      });

      audioVideoMediaRecorderRef.current.ondataavailable = (event) => {
        if (event.data.size > 0) {
          audioVideoRecordedChunksRef.current.push(event.data);
          onRecordedMediaDataAvailable?.(event.data);
        }
      };

      audioVideoMediaRecorderRef.current.onstop = () => {
        const recordedBlob = new Blob(audioVideoRecordedChunksRef.current, {
          type: supportedVideoMimeType,
        });
        dispatch(setRecordedMedia(recordedBlob));
        audioVideoRecordedChunksRef.current = [];
      };

      audioMediaRecorderRef.current.ondataavailable = (event) => {
        if (event.data.size > 0) {
          audioRecordedChunksRef.current.push(event.data);
          onRecordedAudioDataAvailable?.(event.data);
        }
      };

      audioMediaRecorderRef.current.onstop = () => {
        const recordedBlob = new Blob(audioRecordedChunksRef.current, {
          type: supportedAudioMimeType,
        });
        dispatch(setRecordedAudio(recordedBlob));
        audioRecordedChunksRef.current = [];
      };

      audioVideoMediaRecorderRef.current?.start();
      audioMediaRecorderRef.current?.start();
      dispatch(setIsRecording(true));
    } catch (error) {
      console.error(`Error in recording`, error);
    }
  };

  const stopRecording = (stopStream?: boolean) => {
    audioVideoMediaRecorderRef.current?.stop();
    audioMediaRecorderRef.current?.stop();
    if (stopStream) dispatch(stopCameraStream());
    dispatch(setIsRecording(false));
  };

  const contextValue: WebCamContextProps = {
    startWebcam,
    stopWebcam,
    startWebCamAndAudioRecordingSeparately,
    cameraPermissions: mediaPermission,
    videoElementRef: videoRef,
    cameraBrightness: videoBrightness,
    microphoneLevel: audioLevel,
    recordedMedia,
    recordedAudio,
    stopRecording,
    isRecording,
    mediaStream,
  };

  return (
    <WebCamContext.Provider value={contextValue}>
      {children}
    </WebCamContext.Provider>
  );
};

export const useWebCam = (): WebCamContextProps => {
  const context = useContext(WebCamContext);
  if (context === undefined) {
    throw new Error(`useWebCam must be used within a WebCamProvider`);
  }
  return context;
};

export default useWebCam;
