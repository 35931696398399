import { trackClick, trackPage } from '@/segment/eventTracker';
import { PageNames, WidgetFormat, WidgetName } from '@/types/segmentEvents';

export enum CASEventsType {
  COPY_LINK_FROM_BANNER,
  SELECT_LEARNING_RESOURCES,
  SELECT_PRACTICE_TESTS,
  VIEW_LEARNING_RESOURCE,
  SELECT_PRACTICE_QUESTIONS,
  SELECT_ATTEMPTED_QUESTIONS,
  FILTER_CATEGORY_IN_PRACTICE_QUESTIONS,
  START_PRACTICE_TEST,
  CONTINUE_AFTER_CAMERA_ACCESS_CAPTURE,
  BEGIN_TEST_AFTER_INSTRUCTIONS,
  QUIT_PRACTICE_TEST,
  RECORD_PRACTICE_TEST_ANSWER,
  STOP_PRACTICE_TEST_RECORDING,
  RECORD_AGAIN_PRACTICE_TEST_ANSWER,
  SUBMIT_PRACTICE_TEST_ANSWER,
  EXIT_PRACTICE_TEST,
  TRY_ANOTHER_PRACTICE_TEST,
  REVIEW_PRACTICE_TEST_RESULT,

  SELECT_MOCK_TEST,
  SELECT_COMPLETED_MOCK_TEST,
  START_MOCK_TEST,
  REVIEW_COMPLETED_MOCK_TEST,
}
export class CASPrepEventUtil {
  static triggerCASEvent = (
    eventType: CASEventsType,
    meta?: { name?: string; restInfo?: any },
  ) => {
    let widgetName: WidgetName | undefined;
    let widgetFormat: WidgetFormat | undefined;
    let contentName;
    let contentFormat;
    let pageName: PageNames | undefined;

    switch (eventType) {
      case CASEventsType.COPY_LINK_FROM_BANNER:
        pageName = PageNames.APPLICATION_PAGE;
        widgetName = WidgetName.CAS_MOCK_INTERVIEW;
        widgetFormat = WidgetFormat.BANNER;
        contentName = `Copy Link`;
        contentFormat = `Button`;
        break;
      case CASEventsType.SELECT_LEARNING_RESOURCES:
        pageName = PageNames.CAS_MOCK_INTERVIEW;
        widgetName = WidgetName.CAS_MOCK_NAVIGATION;
        widgetFormat = WidgetFormat.SECTION;
        contentName = `Learning Resources`;
        contentFormat = `Button`;
        break;
      case CASEventsType.SELECT_PRACTICE_TESTS:
        pageName = PageNames.CAS_MOCK_INTERVIEW;
        widgetName = WidgetName.CAS_MOCK_NAVIGATION;
        widgetFormat = WidgetFormat.SECTION;
        contentName = `Practice Tests`;
        contentFormat = `Button`;
        break;
      case CASEventsType.VIEW_LEARNING_RESOURCE:
        pageName = PageNames.CAS_MOCK_INTERVIEW_LR;
        widgetName = WidgetName.LEARNING_RESOURCES;
        widgetFormat = WidgetFormat.SECTION;
        contentFormat = `Card`;
        break;
      case CASEventsType.SELECT_PRACTICE_QUESTIONS:
        pageName = PageNames.CAS_MOCK_INTERVIEW_PRACTICE;
        widgetName = WidgetName.LEARNING_RESOURCES_HEADER;
        widgetFormat = WidgetFormat.HEADER;
        contentName = `Practice Questions`;
        contentFormat = `Button`;
        break;
      case CASEventsType.SELECT_ATTEMPTED_QUESTIONS:
        pageName = PageNames.CAS_MOCK_INTERVIEW_PRACTICE;
        widgetName = WidgetName.LEARNING_RESOURCES_HEADER;
        widgetFormat = WidgetFormat.HEADER;
        contentName = `Attempted Questions`;
        contentFormat = `Button`;
        break;
      case CASEventsType.FILTER_CATEGORY_IN_PRACTICE_QUESTIONS:
        pageName = PageNames.CAS_MOCK_INTERVIEW_PRACTICE;
        widgetName = meta?.name as WidgetName;
        widgetFormat = WidgetFormat.SECTION;
        contentFormat = `Button`;
        break;
      case CASEventsType.START_PRACTICE_TEST:
        pageName = PageNames.CAS_MOCK_INTERVIEW_PRACTICE;
        widgetName = WidgetName.PRACTICE_QUESTION_CARD;
        widgetFormat = WidgetFormat.CARD;
        contentName = `Start`;
        contentFormat = `Button`;
        break;
      case CASEventsType.CONTINUE_AFTER_CAMERA_ACCESS_CAPTURE:
        pageName = PageNames.CAS_MOCK_INTERVIEW_PRACTICE;
        widgetName = WidgetName.ENABLE_PERMISSIONS_CAMERA;
        widgetFormat = WidgetFormat.MODAL;
        contentName = `Continue`;
        contentFormat = `Button`;
        break;
      case CASEventsType.BEGIN_TEST_AFTER_INSTRUCTIONS:
        pageName = PageNames.CAS_MOCK_INTERVIEW_PRACTICE;
        widgetName = WidgetName.TEST_INSTRUCTIONS;
        widgetFormat = WidgetFormat.MODAL;
        contentName = `Begin Practice`;
        contentFormat = `Button`;
        break;
      case CASEventsType.QUIT_PRACTICE_TEST:
        pageName = PageNames.CAS_MOCK_INTERVIEW_PRACTICE;
        widgetName = WidgetName.QUIT_PRACTICE;
        widgetFormat = WidgetFormat.MODAL;
        contentName = `Quit`;
        contentFormat = `Button`;
        break;
      case CASEventsType.RECORD_PRACTICE_TEST_ANSWER:
        pageName = PageNames.CAS_MOCK_INTERVIEW_PRACTICE;
        widgetName = WidgetName.PRACTICE_PLAYGROUND;
        widgetFormat = WidgetFormat.SECTION;
        contentName = `Record Answer`;
        contentFormat = `Button`;
        break;
      case CASEventsType.STOP_PRACTICE_TEST_RECORDING:
        pageName = PageNames.CAS_MOCK_INTERVIEW_PRACTICE;
        widgetName = WidgetName.PRACTICE_PLAYGROUND;
        widgetFormat = WidgetFormat.SECTION;
        contentName = `Stop Recording`;
        contentFormat = `Button`;
        break;
      case CASEventsType.RECORD_AGAIN_PRACTICE_TEST_ANSWER:
        pageName = PageNames.CAS_MOCK_INTERVIEW_PRACTICE;
        widgetName = WidgetName.PRACTICE_PLAYGROUND;
        widgetFormat = WidgetFormat.SECTION;
        contentName = `Record Again`;
        contentFormat = `Button`;
        break;
      case CASEventsType.SUBMIT_PRACTICE_TEST_ANSWER:
        pageName = PageNames.CAS_MOCK_INTERVIEW_PRACTICE;
        widgetName = WidgetName.PRACTICE_PLAYGROUND;
        widgetFormat = WidgetFormat.SECTION;
        contentName = `Submit Answer`;
        contentFormat = `Button`;
        break;
      case CASEventsType.EXIT_PRACTICE_TEST:
        pageName = PageNames.CAS_MOCK_INTERVIEW_PRACTICE;
        widgetName = WidgetName.PRACTICE_PERFORMANCE_REVIEW;
        widgetFormat = WidgetFormat.SECTION;
        contentName = `Exit`;
        contentFormat = `Button`;
        break;
      case CASEventsType.TRY_ANOTHER_PRACTICE_TEST:
        pageName = PageNames.CAS_MOCK_INTERVIEW_PRACTICE;
        widgetName = WidgetName.PRACTICE_PERFORMANCE_REVIEW;
        widgetFormat = WidgetFormat.SECTION;
        contentName = `Try Another Question`;
        contentFormat = `Button`;
        break;
      case CASEventsType.REVIEW_PRACTICE_TEST_RESULT:
        pageName = PageNames.CAS_MOCK_INTERVIEW_PRACTICE;
        widgetName = WidgetName.ATTEMPTED_QUESTION_CARD;
        widgetFormat = WidgetFormat.CARD;
        contentName = `Review`;
        contentFormat = `Button`;
        break;

      case CASEventsType.SELECT_MOCK_TEST:
        pageName = PageNames.CAS_MOCK_INTERVIEW;
        widgetName = WidgetName.MOCK_TEST_HEADER;
        widgetFormat = WidgetFormat.HEADER;
        contentName = `Mock Test`;
        contentFormat = `Button`;
        break;
      case CASEventsType.SELECT_COMPLETED_MOCK_TEST:
        pageName = PageNames.CAS_MOCK_INTERVIEW;
        widgetName = WidgetName.MOCK_TEST_HEADER;
        widgetFormat = WidgetFormat.HEADER;
        contentName = `Completed Mock Tests`;
        contentFormat = `Button`;
        break;
      case CASEventsType.START_MOCK_TEST:
        pageName = PageNames.CAS_MOCK_INTERVIEW;
        widgetName = WidgetName.LEARNING_RESOURCES_HEADER;
        widgetFormat = WidgetFormat.SECTION;
        contentName = `Start Mock Test`;
        contentFormat = `Button`;
        break;
      case CASEventsType.REVIEW_COMPLETED_MOCK_TEST:
        pageName = PageNames.CAS_MOCK_INTERVIEW;
        widgetName = WidgetName.COMPLETED_TEST_CARD;
        widgetFormat = WidgetFormat.CARD;
        contentName = `Review`;
        contentFormat = `Button`;
        break;
      default:
        break;
    }

    if (widgetFormat && widgetName) {
      trackClick({
        pageName,
        widgetFormat,
        widgetName,
        contentFormat,
        contentName,
        ...(meta?.restInfo ?? {}),
      });
    }
  };
}

export class CASPrepPageViewedEventUtil {
  static triggerCasPageEvent = (pageName: PageNames, restInfo?: any) => {
    trackPage({
      pageName: pageName,
      ...(restInfo ?? {}),
    });
  };
}
