import React, { useEffect, useState } from 'react';

import { useAppDispatch } from '@/app/hooks';
import {
  updateTokenExpiry,
  updateUserAuthId,
  updateUserDomain,
  updateUserRoles,
} from '@/features/auth/authSlice';

const TokenValidation = ({ children }: { children: React.ReactNode }) => {
  const [fetched, setFetched] = useState<boolean>(false);
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (!fetched) {
      fetch(`/api/tokenValidation`)
        .then(async (res) => {
          const response = await res?.json();
          dispatch(updateUserDomain((response as any)?.domain));
          dispatch(updateUserAuthId((response as any)?.authId));
          dispatch(updateUserRoles((response as any)?.roles));
          dispatch(updateTokenExpiry((response as any)?.isExpired));
        })
        .finally(() => {
          setFetched(true);
        });
    }
  }, [fetched]);

  return <>{children}</>;
};

export default TokenValidation;
