import { createApi } from '@reduxjs/toolkit/query/react';

import { axiosBaseQuery } from '../axios/axiosBaseQuery';

export const engagementBaseApi = createApi({
  reducerPath: `engagementBaseApi`,
  baseQuery: axiosBaseQuery({ baseUrl: `/api/geebeeRoute?path=/engagement` }),
  endpoints: () => ({}),
});

export const engagementBaseApiWithTags = engagementBaseApi.enhanceEndpoints({
  addTagTypes: [],
});
