import { AxiosError } from 'axios';

import SentryError from '@/lib/Sentry';

import { BaseResponse } from '../utils';

import { PRE_LOGIN_ACCESS_PAGES } from './constants';

const getPostLogoutCallbackUrl = (redirectUrl?: string) => {
  if (!window) return null;

  const userIsOnOpenPage = PRE_LOGIN_ACCESS_PAGES.includes(
    window.location.pathname,
  );
  if (userIsOnOpenPage) return null;

  let url;
  if (window?.location?.hostname?.includes(`studyoverseas.ai`)) {
    url = `${window?.location?.origin}/ielts`;
  } else if (window?.location?.href?.includes(`/test-prep`)) {
    url = `${window?.location?.origin}/student-login`;
  } else {
    url = `${window?.location?.origin}/login`;
  }

  if (redirectUrl) {
    url += `?callbackUrl=${redirectUrl}`;
  }

  return url;
};

const trackApplicationCreationFailure = (
  config: AxiosError<BaseResponse<any>>,
) => {
  const url = config?.config?.url?.split(`=`)?.[1];
  const isApplicationCreationUrl =
    url === `/v1/applications` &&
    (config?.config?.method ?? ``)?.toUpperCase() === `POST`;
  if (!isApplicationCreationUrl) return;
  if (
    config?.response?.status == 200 &&
    config?.response?.data?.success === false
  ) {
    new SentryError(`Application Creation Failed, got success false`, {
      payload: config?.config?.data,
    });
  } else if ((config?.response?.status ?? 500) >= 500) {
    new SentryError(`Application Creation Failed, server error`, {
      payload: config?.config?.data,
    });
  } else if (config?.response?.status === 400) {
    new SentryError(`Application Creation Failed with a bad request :(`, {
      payload: config?.config?.data,
    });
  }
};

const fixApiPath = (url: string) => {
  const path = url?.split(`path=`)?.[1];
  if (!path || path?.startsWith(`/`)) {
    return url;
  }
  return url?.split(`path=`).join(`/`);
};

export {
  trackApplicationCreationFailure,
  getPostLogoutCallbackUrl,
  fixApiPath,
};
