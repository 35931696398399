import React from 'react';

import { SnackbarProvider as MainSnackBarProvider } from 'notistack';

import ErrorSnackBar from '@/components/Snackbars/ErrorSnackBar';
import InfoSnackBar from '@/components/Snackbars/InfoSnackBar';
import SuccessSnackBar from '@/components/Snackbars/SuccessSnackBar';
import WarningSnackBar from '@/components/Snackbars/WarningSnackBar';

const SnackBarProvider = (props: { children: React.ReactNode }) => {
  return (
    <MainSnackBarProvider
      Components={{
        error: ErrorSnackBar,
        success: SuccessSnackBar,
        warning: WarningSnackBar,
        info: InfoSnackBar,
      }}
    >
      {props?.children}
    </MainSnackBarProvider>
  );
};

export default SnackBarProvider;
