import React, { useEffect } from 'react';

import { isFalsy } from '@leapfinance/frontend-commons';
import { useRouter } from 'next/router';

import { useAppSelector } from '@/app/hooks';
import LoanMelaAwarenessPopper from '@/components/Tiering/LoanMelaAwarenessPopper';
import { selectUserDetails } from '@/features/user/userSlice';
import { Permissions } from '@/permissions/featuresList';
import { trackClick } from '@/segment/eventTracker';
import { useLazyCheckCspPendingPaymentsQuery } from '@/services/cspPerformanceApi';
import { useGetRewardCreditedEventQuery } from '@/services/rewardCredited';
import { PageNames, WidgetFormat, WidgetName } from '@/types/segmentEvents';
import { localStorageKeys } from '@/utils/GBLocalStorageUtil';
import { shouldShowPopup } from '@/utils/newsletterFeatureUtil';

import { MODAL_TYPES } from './Modals/GlobalModal';
import useModal from './Modals/useModal';

const UK_MID_INTAKE_PAYMENT_DRIVE_MODAL_DISABLE_DATE = `2025-04-05 23:59:59`;
const AnnouncementWrapper = (props: { children: React.ReactNode }) => {
  const { showModal } = useModal();
  const router = useRouter();
  const { data: rewardCreditedEventData } = useGetRewardCreditedEventQuery(
    undefined,
    {
      refetchOnMountOrArgChange: true,
    },
  );

  const [triggerCheckPendingPayment, { data: pendingPayment }] =
    useLazyCheckCspPendingPaymentsQuery();

  const cspProfileData = useAppSelector((state) => selectUserDetails(state));

  const isInternationalCsp = cspProfileData?.cspZone === `International`;

  const isStudentUser =
    cspProfileData?.permissions?.[0] === Permissions.STUDENT;

  const isAdminUser = cspProfileData?.permissions?.[0] === Permissions.ADMIN;

  useEffect(() => {
    const checkPendingPayment = async () => {
      if (router?.pathname?.toString() === `/summary-dashboard`) {
        await triggerCheckPendingPayment();
      }
    };

    checkPendingPayment();
  }, []);

  useEffect(() => {
    if (
      isFalsy(cspProfileData?.permissions) ||
      isStudentUser ||
      !router?.isReady
    )
      return;
    const routesWhereToNotShow = [
      `/`,
      `/login`,
      `/changepwd`,
      `/resetpwd`,
      `/test-prep/video-course`,
      `/test-prep/video-course/[slug]`,
      `/test-prep/mock-test`,
      `/test-prep/mock-test/[mock_test_id]`,
      `/test-prep/mock-test/result/[mock_test_id]`,
      `/test-prep/mock-test/speaking/result/[id]`,
      `/ielts`,
      `/student-login`,
      `/cas/[prepType]`,
      `/cas/practice/[examId]`,
      `/cas/practice/result/[examId]`,
      `/cas/mock-test/[examId]`,
      `/cas/mock-test/result/[examId]`,
    ];

    if (routesWhereToNotShow?.includes(router?.pathname?.toString())) return;

    if (
      rewardCreditedEventData?.data?.includes(`PAYMENT_APPLICATION`) ||
      rewardCreditedEventData?.data?.includes(`STI_APPLICATION`)
    ) {
      showModal({
        modalType: MODAL_TYPES.REWARDS_NOTIFY_MODAL,
        modalProps: {
          type: rewardCreditedEventData?.data?.includes(`PAYMENT_APPLICATION`)
            ? `payment`
            : rewardCreditedEventData?.data?.includes(`STI_APPLICATION`)
            ? `application`
            : null,
        },
      });
    }

    const showUKPaymentDriveModal =
      isAdminUser &&
      !isInternationalCsp &&
      shouldShowPopup({
        key: localStorageKeys.UK_MID_INTAKE_PAYMENT_DRIVE,
        skipTimeSlot: false,
        modalDisableDateTime: UK_MID_INTAKE_PAYMENT_DRIVE_MODAL_DISABLE_DATE,
        maxTimesToShow: 5,
        maxTimesToShowPerDay: 2,
        minGapHours: 4,
      });

    if (
      showUKPaymentDriveModal &&
      !routesWhereToNotShow?.includes(router?.pathname?.toString())
    ) {
      showModal({
        modalType: MODAL_TYPES.NO_RESULTS,
        modalProps: {
          body: (
            <LoanMelaAwarenessPopper
              redirectionUrl={`/applications?preApply=[{"key":"countries","value":["UK"]},{"key":"intakes","value":["APRIL_2025","MAY_2025","JUNE_2025"]},{"key":"paymentStatuses","value":["REFUNDED","NOT_PAID",null]},{"key":"applicationStages","value":["UNCONDITIONAL_ADMIT_RECEIVED","CONDITIONAL_ADMIT_RECEIVED","ADMIT_ACCEPTED"]}]`}
              imageUrl={`https://ik.imagekit.io/v8frh8b7rf/WhatsApp%20Image%202025-03-28%20at%201.48.35%20PM.jpeg?updatedAt=1743151113486`}
              storageKey={localStorageKeys.UK_MID_INTAKE_PAYMENT_DRIVE}
              containerClassName="max-w-[650px]"
              closeButtonClassName="hidden"
              closeModalOnSubmit={true}
              imageClassName="h-full object-contain"
              onImageClick={() => {
                trackClick({
                  pageName: PageNames.UK_MID_INTAKE_PAYMENT_DRIVE,
                  contentFormat: `Modal`,
                  contentName: `Apply Now and Close`,
                  widgetFormat: WidgetFormat.MODAL,
                  widgetName: WidgetName.UK_MID_INTAKE_PAYMENT_DRIVE,
                });
              }}
            />
          ),
        },
      });
    }
  }, [
    router,
    isInternationalCsp,
    cspProfileData,
    pendingPayment,
    rewardCreditedEventData,
  ]);

  return <>{props.children}</>;
};

export default AnnouncementWrapper;
