import type { BaseQueryFn } from '@reduxjs/toolkit/query';
import type { AxiosError, AxiosRequestConfig } from 'axios';

import axiosInstance from './axiosInstance';

export type AxiosBaseQueryArgsType =
  | string
  | {
      url: string;
      method?: AxiosRequestConfig['method'];
      body?: AxiosRequestConfig['data'];
      params?: AxiosRequestConfig['params'];
      headers?: AxiosRequestConfig['headers'];
    };
export type AxiosBaseQuery = BaseQueryFn<
  AxiosBaseQueryArgsType,
  unknown,
  unknown
>;

const axiosBaseQuery =
  ({ baseUrl }: { baseUrl: string } = { baseUrl: `` }): AxiosBaseQuery =>
  async (config) => {
    try {
      if (typeof config === `string`) {
        const result = await axiosInstance({
          url: baseUrl + config,
          method: `GET`,
        });
        return { data: result.data };
      } else {
        const { url, method, body, params, headers } = config;
        const result = await axiosInstance({
          url: baseUrl + url,
          method,
          data: body,
          params,
          headers,
        });
        return { data: result.data };
      }
    } catch (axiosError) {
      const err = axiosError as AxiosError;
      return {
        error: {
          status: err.response?.status,
          data: err.response?.data || err.message,
        },
      };
    }
  };

export { axiosBaseQuery };
