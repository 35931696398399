import { createApi } from '@reduxjs/toolkit/query/react';

import { axiosBaseQuery } from '../axios/axiosBaseQuery';

export const bookmarkListBaseApi = createApi({
  reducerPath: `bookmarkListApi`,
  baseQuery: axiosBaseQuery({ baseUrl: `/api/geebeeRoute?path=/curated-list` }),
  endpoints: () => ({}),
});

export const bookmarkListBaseApiWithTags = bookmarkListBaseApi.enhanceEndpoints(
  {
    addTagTypes: [`Bookmark`, `BookmarkListDetails`],
  },
);
