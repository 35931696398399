import { clearAuthSlice } from '@/features/auth/authSlice';

import { PRE_LOGIN_ACCESS_PAGES } from './axios/constants';
import { getPostLogoutCallbackUrl } from './axios/utils';

export const triggerLogout = async (redirectToSameUrlPostLogin?: boolean) => {
  let redirectUrl = getPostLogoutCallbackUrl(
    redirectToSameUrlPostLogin ? window?.location?.href : undefined,
  );

  const { store } = await import(`@/app/store`);
  const { logoutBaseApi } = await import(`@/services/base/logoutRoute`);

  const promise = store.dispatch(logoutBaseApi.endpoints.logout.initiate());
  promise?.unwrap()?.then((response) => {
    if (response?.success) {
      store.dispatch(clearAuthSlice());
      if (!PRE_LOGIN_ACCESS_PAGES.includes(window?.location?.pathname)) {
        window.location.replace(redirectUrl ?? `/login`);
      }
    }
  });
};
