import { createSlice, type PayloadAction } from '@reduxjs/toolkit';

import { RootState } from '@/app/store';

interface IAuthSlice {
  isAuthenticated: boolean;
  domain: string | null;
  roles: string[];
  isExpired: boolean | null;
  authUserId: number | null;
}
const authSlice = createSlice({
  name: `authSlice`,
  initialState: {
    isAuthenticated: true,
    domain: null,
    roles: [],
    isExpired: null,
    authUserId: null,
  } as IAuthSlice,
  reducers: {
    updateAuthentication: (state, action: PayloadAction<boolean>) => {
      state.isAuthenticated = action.payload;
    },
    updateUserDomain: (state, action: PayloadAction<string | null>) => {
      state.domain = action?.payload;
    },
    updateUserRoles: (state, action: PayloadAction<string[]>) => {
      state.roles = action?.payload;
    },
    updateTokenExpiry: (state, action: PayloadAction<boolean>) => {
      state.isExpired = action?.payload;
    },
    updateUserAuthId: (state, action: PayloadAction<number | null>) => {
      state.authUserId = action?.payload;
    },
    clearAuthSlice: (state) => {
      state.roles = [];
      state.domain = null;
      state.isAuthenticated = false;
      state.isExpired = null;
      state.authUserId = null;
    },
  },
});
export const selectIsUserAuthenticated = (store: RootState) =>
  store.authSlice?.isAuthenticated;
export const selectUserDomain = (store: RootState) => store.authSlice?.domain;
export const selectUserRoles = (store: RootState) => store.authSlice?.roles;
export const selectTokenExpiry = (store: RootState) =>
  store.authSlice?.isExpired;
export const selectUserAuthId = (store: RootState) =>
  store.authSlice?.authUserId;

export const {
  updateAuthentication,
  updateUserDomain,
  updateUserRoles,
  clearAuthSlice,
  updateTokenExpiry,
  updateUserAuthId,
} = authSlice.actions;
export default authSlice;
