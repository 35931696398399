import { createApi } from '@reduxjs/toolkit/query/react';

import { axiosBaseQuery } from '../axios/axiosBaseQuery';

export const voucherBaseApi = createApi({
  reducerPath: `voucherBaseApi`,
  baseQuery: axiosBaseQuery({ baseUrl: `/api/geebeeRoute?path=/voucher` }),
  endpoints: () => ({}),
});

export const voucherBaseApiWithTags = voucherBaseApi.enhanceEndpoints({
  addTagTypes: [`Voucher`],
});
