import { geebeeRootBaseApiWithTags } from '@/services/base/geebeeRoute';
import { paths } from '@/types/schema';
import {
  GetCspMilestoneStiCountResponseType,
  GetDomainExistsRequestType,
  GetDomainExistsResponseType,
  GetKamOptionsResponseType,
  getRemarksResponseType,
  StudentsStagesByCreatedMonthRequestType,
  StudentsStagesByCreatedMonthResponseType,
  StudentsStagesByIntakeRequestType,
  StudentsStagesByIntakeResponseType,
  StudentsWithApplicationByIntakeRequestType,
  StudentsWithApplicationByIntakeResponseType,
  StudentsWithApplicationByMonthRequestType,
  StudentsWithApplicationByMonthResponseType,
  UpdateCspOnboardingDetailsRequestType,
  UpdateCspOnboardingDetailsResponseType,
  UploadCspOnboardingDocumentsRequestType,
  UploadCspOnboardingDocumentsResponseType,
} from '@/types/Services';

import { AxiosBaseQueryArgsType } from './axios/axiosBaseQuery';
import { documentUploadBaseApi } from './base/documentUpload';

import { transformResponse } from './utils';

type GetCspPendingPaymentsResponseType =
  paths['/v1/csps/pending-payments']['get']['responses']['200']['schema'];
type ExportPendingPaymentsFileResponseType =
  paths[`/v1/students/export/excel/pending-payments`]['post']['responses']['200'];

export const cspManagementApi = geebeeRootBaseApiWithTags.injectEndpoints({
  endpoints: (builder) => ({
    fetchStudentApplicationsByMonthStats: builder.mutation<
      StudentsWithApplicationByMonthResponseType,
      StudentsWithApplicationByMonthRequestType
    >({
      query: (payload) => {
        const queryObj: any = {
          url: `v1/csps/performance/student-application-by-month`,
          body: payload,
          method: `POST`,
        };
        return queryObj;
      },
      transformResponse: transformResponse,
    }),
    fetchStudentApplicationsByIntakeStats: builder.mutation<
      StudentsWithApplicationByIntakeResponseType,
      StudentsWithApplicationByIntakeRequestType
    >({
      query: (payload) => {
        const queryObj: any = {
          url: `v1/csps/performance/student-application-by-intake`,
          body: payload,
          method: `POST`,
        };
        return queryObj;
      },
      transformResponse: transformResponse,
    }),
    fetchStudentStagesByIntakeStats: builder.mutation<
      StudentsStagesByIntakeResponseType,
      StudentsStagesByIntakeRequestType
    >({
      query: (payload) => {
        const queryObj: any = {
          url: `v1/csps/performance/student-stage-by-intake`,
          body: payload,
          method: `POST`,
        };
        return queryObj;
      },
      transformResponse: transformResponse,
    }),
    fetchStudentStagesByCreatedMonthStats: builder.mutation<
      StudentsStagesByCreatedMonthResponseType,
      StudentsStagesByCreatedMonthRequestType
    >({
      query: (payload) => {
        const queryObj: any = {
          url: `v1/csps/performance/student-stage-by-created-month`,
          body: payload,
          method: `POST`,
        };
        return queryObj;
      },
      transformResponse: transformResponse,
    }),
    getRemarks: builder.query<
      getRemarksResponseType,
      { cspId: number; pageNumber: number; pageSize: number }
    >({
      query: (payload) =>
        `v1/csps/remark/${payload.cspId}&pageNumber=${payload.pageNumber}&pageSize=${payload.pageSize}`,
    }),
    getCspMilestoneStiCount: builder.query<
      GetCspMilestoneStiCountResponseType,
      { cspId: number }
    >({
      query: ({ cspId }) => `/v1/csps/leaderboard/sti-count/${cspId}`,
      transformResponse: transformResponse,
    }),
    updateCspOnboardingInfo: builder.mutation<
      UpdateCspOnboardingDetailsResponseType,
      UpdateCspOnboardingDetailsRequestType
    >({
      query: (body) => {
        return {
          method: `POST`,
          url: `/v1/csps/onboarding`,
          body,
        };
      },
      transformResponse,
    }),
    getKamOptions: builder.query<GetKamOptionsResponseType, void>({
      query: () => `/v1/csps/onboarding/viewer-csp-onboarding-options`,
      transformResponse: transformResponse,
    }),
    getDomainExists: builder.mutation<
      GetDomainExistsResponseType,
      GetDomainExistsRequestType
    >({
      query: (body) => {
        return {
          method: `POST`,
          url: `/v1/csps/onboarding/verify-domain/${body.domain}`,
          // body,
        };
      },
      transformResponse,
    }),
    checkCspPendingPayments: builder.query<
      GetCspPendingPaymentsResponseType,
      void
    >({
      query: () => ({
        url: `/v1/csps/pending-payments`,
        method: `GET`,
      }),
      transformResponse,
    }),
    exportPendingPaymentsFile: builder.mutation<
      ExportPendingPaymentsFileResponseType,
      void
    >({
      query: () => {
        return {
          method: `POST`,
          url: `/v1/students/export/excel/pending-payments`,
        };
      },
      transformResponse,
    }),
  }),
});
export const cspRemarksApi = documentUploadBaseApi.injectEndpoints({
  endpoints: (builder) => ({
    updateRemarks: builder.mutation<
      boolean,
      {
        file?: File;
        comment?: string;
        cspId: number;
        pageNumber: number;
        pageSize: number;
      }
    >({
      query: ({ file, comment, cspId, pageNumber, pageSize }) => {
        if (!file && !comment && !pageNumber && !pageSize) return null;
        const queryObj: any = {
          url: `/v1/csps/remark/${cspId}`,
          method: `POST`,
        };
        const formData = new FormData();
        formData.append(`remark`, comment || ``);
        formData.append(`cspId`, String(cspId));
        if (file) {
          formData.append(`file`, file);
        }
        queryObj[`body`] = formData;
        return queryObj;
      },
    }),
    uploadCspOnboardingDocuments: builder.mutation<
      UploadCspOnboardingDocumentsResponseType,
      UploadCspOnboardingDocumentsRequestType
    >({
      query: (payload) => {
        const args: AxiosBaseQueryArgsType = {
          url: `/v1/csps/${payload?.cspId}/documents`,
          method: `POST`,
        };
        const formData = new FormData();
        formData.append(`fileLabel`, payload?.fileLabel || ``);
        formData.append(`keyName`, payload?.keyName);
        if (payload?.file) {
          formData.append(`file`, payload?.file as File);
        }
        args[`body`] = formData;
        return args;
      },
    }),
  }),
});

export const {
  useFetchStudentApplicationsByMonthStatsMutation,
  useFetchStudentApplicationsByIntakeStatsMutation,
  useFetchStudentStagesByIntakeStatsMutation,
  useFetchStudentStagesByCreatedMonthStatsMutation,
  useGetRemarksQuery,
  useLazyGetRemarksQuery,
  useLazyGetCspMilestoneStiCountQuery,
  useUpdateCspOnboardingInfoMutation,
  useGetKamOptionsQuery,
  useGetDomainExistsMutation,
  useLazyCheckCspPendingPaymentsQuery,
  useExportPendingPaymentsFileMutation,
} = cspManagementApi;

export const {
  useUpdateRemarksMutation,
  useUploadCspOnboardingDocumentsMutation,
} = cspRemarksApi;
