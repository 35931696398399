import { createApi } from '@reduxjs/toolkit/query/react';

import {
  CompletedTestsRequestType,
  CompletedTestsResponseType,
  FinishMockTestRequestType,
  FinishMockTestResponseType,
  GetAllCasCompletedTestsRequestType,
  GetAllCasCompletedTestsResponseType,
  GetAllCasLearningResourcesResponseType,
  GetAllCasPracticeTestsRequestType,
  GetAllCasPracticeTestsResponseType,
  GetCasExamResultRequestType,
  GetCasExamResultResponseType,
  GetMockTestMetaRequestType,
  GetMockTestMetaResponseType,
  GetQuestionForCasExamRequestType,
  GetQuestionForCasExamResponseType,
  GetTestDetailsRequestType,
  GetTestDetailsResponseType,
  StartMockTestRequestType,
  StartMockTestResponseType,
  SubmitCasExamRequestType,
  SubmitCasExamResponseType,
} from '@/types/casServices';

import { axiosBaseQuery } from '../axios/axiosBaseQuery';
import { transformResponse } from '../utils';

export const casRootBaseApi = createApi({
  reducerPath: `casBaseApi`,
  baseQuery: axiosBaseQuery({
    baseUrl: `/api/geebeeRoute?path=/cas-mock-interview`,
  }),
  endpoints: () => ({}),
});

export const casRootBaseApiWithTags = casRootBaseApi.enhanceEndpoints({
  addTagTypes: [],
});

export const casServiceApi = casRootBaseApiWithTags.injectEndpoints({
  endpoints: (builder) => ({
    getAllLearningResources: builder.query<
      GetAllCasLearningResourcesResponseType,
      void
    >({
      query: () => `/learning-resources`,
      transformResponse,
    }),
    getAllPracticeTests: builder.query<
      GetAllCasPracticeTestsResponseType,
      GetAllCasPracticeTestsRequestType
    >({
      query: ({ guid }) => `/practice-questions/${guid}`,
      transformResponse,
    }),
    getAllCompletedTests: builder.query<
      GetAllCasCompletedTestsResponseType,
      GetAllCasCompletedTestsRequestType
    >({
      query: ({ guid }) => `/completed-questions/${guid}`,
      transformResponse,
    }),
    getCasQuestionsForExam: builder.query<
      GetQuestionForCasExamResponseType,
      GetQuestionForCasExamRequestType
    >({
      query: ({ questionId, guid }) =>
        `/practice-question/${guid}/${questionId}`,
      transformResponse,
    }),
    getCasExamResult: builder.query<
      GetCasExamResultResponseType,
      GetCasExamResultRequestType
    >({
      query: ({ resultId }) => `/practice-question-answer/${resultId}`,
      transformResponse,
    }),
    submitCasExamAnswer: builder.mutation<
      SubmitCasExamResponseType,
      SubmitCasExamRequestType
    >({
      query: (body) => {
        return {
          method: `POST`,
          url: `/submit-practice-question`,
          body,
        };
      },
      transformResponse,
    }),
    startMockTest: builder.query<
      StartMockTestResponseType,
      StartMockTestRequestType
    >({
      query: ({ guid }) => `/start-mock-test/${guid}`,
      transformResponse,
    }),
    finishMockTest: builder.query<
      FinishMockTestResponseType,
      FinishMockTestRequestType
    >({
      query: ({ testId }) => `/finish-mock-test/${testId}`,
      transformResponse,
    }),
    completedTests: builder.query<
      CompletedTestsResponseType,
      CompletedTestsRequestType
    >({
      query: ({ guid }) => `/completed-tests/${guid}`,
      transformResponse,
    }),
    getTestDetails: builder.query<
      GetTestDetailsResponseType,
      GetTestDetailsRequestType
    >({
      query: ({ testId }) => `/test-details/${testId}`,
      transformResponse,
    }),
    getMockTestMeta: builder.query<
      GetMockTestMetaResponseType,
      GetMockTestMetaRequestType
    >({
      query: ({ guid }) => `/mock-test/meta/${guid}`,
      transformResponse,
    }),
  }),
});

export const {
  useGetAllPracticeTestsQuery,
  useGetAllCompletedTestsQuery,
  useGetAllLearningResourcesQuery,
  useGetCasExamResultQuery,
  useGetCasQuestionsForExamQuery,
  useSubmitCasExamAnswerMutation,
  useStartMockTestQuery,
  useLazyStartMockTestQuery,
  useFinishMockTestQuery,
  useCompletedTestsQuery,
  useLazyFinishMockTestQuery,
  useGetTestDetailsQuery,
  useGetMockTestMetaQuery,
} = casServiceApi;
