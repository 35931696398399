import { NextRouter } from 'next/router';

import { store } from '@/app/store';
import {
  CASEventsType,
  CASPrepEventUtil,
} from '@/components/CASPrep/CASPrepEventUtil';
import { Permissions } from '@/permissions/featuresList';
import { SingleApplicationType } from '@/types/application';
import { definitions } from '@/types/schema';
import { QueryParams } from '@/utils/constants';

export const RECORDING_COUNTDOWN = 3;
export const ALL_QUESTIONS_OBJECT = {
  label: `All Questions`,
  category: `ALL_QUESTIONS`,
  questions: [],
};

export enum ExamStateEnum {
  QUESTION_PREVIEW,
  RECORD_RESPONSE,
  RESPONSE_PREVIEW,
  FEEDBACK_PREVIEW,
}

export const validateIfCasBannerShouldBeDisplayed = (
  application?: SingleApplicationType,
  preVisaData?: definitions['PreVisaDetailsResponseDtoCsp'],
) => {
  const isApplicationCountryUK = application?.country === `UK`;
  const stagesForCasBanner: SingleApplicationType['stage'][] = [
    `ADMIT_ACCEPTED`,
    `CONDITIONAL_ADMIT_RECEIVED`,
    `UNCONDITIONAL_ADMIT_RECEIVED`,
    `APPLICATION_SENT_TO_UNIVERSITY`,
  ];
  const preVisaStagesToExcludeForCasBanner: definitions['PreVisaDetailsResponseDtoCsp']['preVisaStatus'][] =
    [`PRE_VISA_RECEIVED`, `PRE_VISA_REJECTED`];
  const isCasPrepIdentifierPresent = application?.casPrepIdentifier;

  const loggedInUserData = store?.getState().user?.userConfig;

  const isSubAgentUser =
    loggedInUserData?.permissions?.[0] === Permissions.SUB_AGENT;

  return (
    loggedInUserData &&
    !isSubAgentUser &&
    isCasPrepIdentifierPresent &&
    isApplicationCountryUK &&
    stagesForCasBanner?.includes(application?.stage) &&
    !preVisaStagesToExcludeForCasBanner?.includes(preVisaData?.preVisaStatus)
  );
};

export enum CasPrepTypes {
  LEARNING = `learning`,
  PRACTICE = `practice`,
  MOCK = `mock`,
}

export type CASPracticeSection = `PRACTICE` | `ATTEMPTED`;
export type CASMockTestSection = `MOCK_TEST` | `ATTEMPTED`;

export const CASSectionsQueryValue: Record<CasPrepTypes, string> = {
  learning: `learning-resource`,
  practice: `practice`,
  mock: `mock-test`,
};

export const CasPrepTypesLabel: Record<CasPrepTypes, string> = {
  learning: `Learning Resources`,
  practice: `Practice`,
  mock: `Mock Tests`,
};

export const CASPracticeSection = {};

export const CasPrepSections = [
  {
    label: `Learning Resources`,
    value: CasPrepTypes.LEARNING,
    iconClasses: `fi fi-rr-folder-open`,
    onClick: ({ guid, router }: { guid?: string; router?: NextRouter }) => {
      CASPrepEventUtil.triggerCASEvent(
        CASEventsType.SELECT_LEARNING_RESOURCES,
        {
          restInfo: { prepIdentifer: guid },
        },
      );
      router?.push(
        `/cas/${CasPrepTypes.LEARNING}?${QueryParams?.CAS_PREP_IDENTIFIER}=${guid}`,
      );
    },
  },
  {
    label: `Practice`,
    value: CasPrepTypes.PRACTICE,
    iconClasses: `fi fi-rr-pen-clip`,
    onClick: ({ guid, router }: { guid?: string; router?: NextRouter }) => {
      CASPrepEventUtil.triggerCASEvent(CASEventsType.SELECT_PRACTICE_TESTS, {
        restInfo: { prepIdentifer: guid },
      });
      router?.push(
        `/cas/${CasPrepTypes.PRACTICE}?${QueryParams.CAS_PREP_IDENTIFIER}=${guid}`,
      );
    },
  },
  {
    label: `Mock Tests`,
    value: CasPrepTypes.MOCK,
    iconClasses: `fi fi-rr-e-learning`,
    onClick: ({ guid, router }: { guid?: string; router?: NextRouter }) => {
      CASPrepEventUtil.triggerCASEvent(CASEventsType.SELECT_PRACTICE_TESTS, {
        restInfo: { prepIdentifer: guid },
      });
      router?.push(
        `/cas/${CasPrepTypes.MOCK}?${QueryParams.CAS_PREP_IDENTIFIER}=${guid}`,
      );
    },
  },
];
