import { CFCourse } from '@/components/CF/Dashbaord/helpers';
import { definitions, operations } from '@/types/schema';
import {
  CreateShortlistRequestResponseType,
  DeleteShortlistRequestResponseType,
  GetShortlistRequestValidationResponseType,
  GetShortlistSimilarCoursesRequestType,
  GetShortlistSimilarCoursesResponseType,
  UpdateShortlistRequestResponseType,
} from '@/types/Services';

import { geebeeRootBaseApiWithTags } from './base/geebeeRoute';

import { BaseResponse, transformResponse } from './utils';

export const shortlistRequestApi = geebeeRootBaseApiWithTags.injectEndpoints({
  endpoints: (builder) => ({
    createShortlistRequest: builder.mutation<
      CreateShortlistRequestResponseType,
      { studentId: number; body: definitions[`StudentPreferencesDto`] }
    >({
      query: ({ studentId, body }) => ({
        url: `/v1/shortlist/shortlist-request/student/${studentId}`,
        method: `POST`,
        body,
      }),
      transformResponse: ({ success, data, message }) => {
        if (!success) {
          return {
            data: null,
            message,
            apiError: true,
          } as unknown as CreateShortlistRequestResponseType;
        } else {
          return { data } as unknown as CreateShortlistRequestResponseType;
        }
      },
      invalidatesTags: [`ShortlistRequest`, `Student`],
    }),
    updateShortlistRequest: builder.mutation<
      UpdateShortlistRequestResponseType,
      { body: definitions['StudentPreferencesDto']; shortListRequestId: number }
    >({
      query: ({ shortListRequestId, body }) => ({
        url: `/v1/shortlist/shortlist-request/${shortListRequestId}`,
        method: `PUT`,
        body,
      }),
      transformResponse: ({ success, data, message }) => {
        if (!success) {
          return {
            data: null,
            message,
            apiError: true,
          } as unknown as UpdateShortlistRequestResponseType;
        } else {
          return { data } as unknown as UpdateShortlistRequestResponseType;
        }
      },
      invalidatesTags: [`ShortlistRequest`],
    }),
    deleteShortlistRequest: builder.mutation<
      DeleteShortlistRequestResponseType,
      { shortListRequestId: number }
    >({
      query: ({ shortListRequestId }) => ({
        url: `/v1/shortlist/shortlist-request/${shortListRequestId}`,
        method: `DELETE`,
      }),
      transformResponse: ({ success, data, message }) => {
        if (!success) {
          return {
            data: null,
            message,
            apiError: true,
          } as unknown as DeleteShortlistRequestResponseType;
        } else {
          return { data } as unknown as DeleteShortlistRequestResponseType;
        }
      },
      invalidatesTags: [`ShortlistRequest`, `Student`],
    }),
    validateShortlistRequest: builder.mutation<
      GetShortlistRequestValidationResponseType,
      { studentId: number; body: { preferredCountryGroup: string } }
    >({
      query: ({ studentId, body }) => ({
        url: `/v1/shortlist/shortlist-request/student/${studentId}/validate`,
        method: `POST`,
        body,
      }),
      transformResponse: ({ success, data, message }) => {
        if (!success) {
          return {
            data: null,
            message,
            apiError: true,
          } as unknown as GetShortlistRequestValidationResponseType;
        } else {
          return {
            data,
          } as unknown as GetShortlistRequestValidationResponseType;
        }
      },
      // invalidatesTags: [`ShortlistRequest`],
    }),
    updateShortlistStatus: builder.mutation<
      definitions['ResponseWrapper«boolean»'],
      {
        shortlistId: operations[`updateStudentShortlistRequestStatusUsingPUT`][`parameters`][`path`][`shortListRequestId`];
        status: any;
      }
    >({
      query: ({ shortlistId, status }) => ({
        url: `v1/shortlist/shortlist-request/update-status/${shortlistId}`,
        method: `PUT`,
        body: { status },
      }),
      transformResponse: ({ success, data, message }) => {
        if (!success) {
          return {
            data: null,
            message,
            apiError: true,
          } as unknown as definitions['ResponseWrapper«boolean»'];
        } else {
          return {
            data,
          } as unknown as definitions['ResponseWrapper«boolean»'];
        }
      },
      invalidatesTags: [`ShortlistRequest`],
    }),

    userEmailSuggestions: builder.query<
      definitions['ResponseWrapper«List«string»»'],
      void
    >({
      query: () => `users/email-suggestions`,
    }),
    postShortlistFeedback: builder.mutation<
      definitions['ResponseWrapper«boolean»'],
      {
        studentId: number;
        shortlistId: number;
        body: definitions['ShortlistFeedbackRequestDto'];
      }
    >({
      query: ({ studentId, shortlistId, body }) => ({
        url: `/v1/shortlist/feedback/${studentId}/${shortlistId}`,
        method: `POST`,
        body,
      }),
      invalidatesTags: [`ShortlistRequest`],
    }),
    getStudentShortlistedCourses: builder.query<
      BaseResponse<Array<CFCourse>>,
      { studentId: number }
    >({
      query: ({ studentId }) => `/v1/shortlist/student-shortlists/${studentId}`,
      transformResponse: transformResponse,
      providesTags: [`ShortlistRequest`],
    }),
    getShortlistedSimilarCourses: builder.mutation<
      GetShortlistSimilarCoursesResponseType,
      GetShortlistSimilarCoursesRequestType
    >({
      query: (body) => ({
        url: `/v1/shortlist/similar-courses`,
        method: `POST`,
        body,
      }),
      transformResponse: transformResponse,
    }),
  }),
});

export const {
  useCreateShortlistRequestMutation,
  useUpdateShortlistRequestMutation,
  useDeleteShortlistRequestMutation,
  useValidateShortlistRequestMutation,
  useUpdateShortlistStatusMutation,
  useUserEmailSuggestionsQuery,
  usePostShortlistFeedbackMutation,
  useGetStudentShortlistedCoursesQuery,
  useLazyGetStudentShortlistedCoursesQuery,
  useGetShortlistedSimilarCoursesMutation,
} = shortlistRequestApi;
