// /v1/onboarding-form/csp-details/{cspId}
import { createApi } from '@reduxjs/toolkit/query/react';

import { axiosBaseQuery } from '../axios/axiosBaseQuery';

export const onboardingFormBaseApi = createApi({
  reducerPath: `onboardingFormBaseApi`,
  baseQuery: axiosBaseQuery({
    baseUrl: `/api/geebeeRoute?path=/v1/onboarding-form`,
  }),
  endpoints: () => ({}),
});

export const onboardingFormBaseApiWithTags =
  onboardingFormBaseApi.enhanceEndpoints({
    addTagTypes: [`CSPDetails`],
  });
