import { createSlice, type PayloadAction } from '@reduxjs/toolkit';

import { RootState } from '@/app/store';
import { MAX_RECORDING_ATTEMPTS } from '@/components/CASPrep/MockTests/constant';
import { ExamStateEnum } from '@/components/CASPrep/utils';
import { casServiceApi } from '@/services/base/casRoute';
import {
  GetAllCasCompletedTestsResponseType,
  GetAllCasPracticeTestsResponseType,
  GetCasExamResultResponseType,
  GetTestDetailsResponseType,
  StartMockTestResponseType,
} from '@/types/casServices';
import { definitions } from '@/types/schema';

type TestType = 'PRACTICE' | 'MOCK';

interface CasPrepStateType {
  activeExamState: ExamStateEnum;
  allPracticeTests: GetAllCasPracticeTestsResponseType['data'];
  allCompletedTests: GetAllCasCompletedTestsResponseType['data'];
  activeQuestionData?: definitions['CASQuestionResponseDto'];
  isQuitAttempted: boolean;
  activeResultData?: GetCasExamResultResponseType['data'];
  testType: TestType;
  mockTestQuestions: Exclude<
    StartMockTestResponseType[`data`],
    undefined
  >[`questions`];
  mockTestCurrentQuestionIndex: number;
  mockTestCurrentQuestionRecordingCount: number;
  mockTestResults: GetTestDetailsResponseType['data'];
  mockTestId?: number;
  isCountdownActive: boolean;
  isCameraTestingDone: boolean;
  recordedMedia: Blob | null;
  recordedAudio: Blob | null;
}

const initialState: CasPrepStateType = {
  isCountdownActive: false,
  activeExamState: ExamStateEnum.QUESTION_PREVIEW,
  allCompletedTests: [],
  allPracticeTests: [],
  activeQuestionData: {},
  isQuitAttempted: false,
  activeResultData: undefined,

  testType: `PRACTICE`,
  mockTestCurrentQuestionIndex: 0,
  mockTestCurrentQuestionRecordingCount: 1,
  mockTestQuestions: [],
  mockTestResults: undefined,
  isCameraTestingDone: false,
  recordedAudio: null,
  recordedMedia: null,
};

export const casPrepSlice = createSlice({
  name: `casPrepSlice`,
  initialState,
  reducers: {
    updateCasActiveExamState: (state, action: PayloadAction<ExamStateEnum>) => {
      state.activeExamState = action.payload;
    },
    updateIsQuitAttempted: (state, action: PayloadAction<boolean>) => {
      state.isQuitAttempted = action.payload;
    },

    updateTestType: (state, action: PayloadAction<TestType>) => {
      state.testType = action.payload;
    },
    moveToNextMockQuestion: (state) => {
      if (
        state.mockTestCurrentQuestionIndex <
        (state.mockTestQuestions?.length ?? 0)
      ) {
        state.mockTestCurrentQuestionIndex =
          state.mockTestCurrentQuestionIndex + 1;
        state.activeExamState = ExamStateEnum.QUESTION_PREVIEW;
        state.mockTestCurrentQuestionRecordingCount = 1;
      }
    },
    updateRecordingCount: (state) => {
      state.mockTestCurrentQuestionRecordingCount =
        state.mockTestCurrentQuestionRecordingCount + 1;
    },
    resetCasState: (state) => {
      return initialState;
    },
    updateCountDownActive: (state, action: PayloadAction<boolean>) => {
      state.isCountdownActive = action.payload;
    },
    updateIsCameraTestingDone: (state, action: PayloadAction<boolean>) => {
      state.isCameraTestingDone = action.payload;
    },
    updateRecordedMedia: (state, action: PayloadAction<Blob | null>) => {
      state.recordedMedia = action.payload;
    },
    updateRecordedAudio: (state, action: PayloadAction<Blob | null>) => {
      state.recordedAudio = action.payload;
    },
  },
  extraReducers(builder) {
    builder
      .addMatcher(
        casServiceApi.endpoints.getAllPracticeTests.matchFulfilled,
        (state, { payload }) => {
          state.allPracticeTests = payload?.data;
        },
      )
      .addMatcher(
        casServiceApi.endpoints.getAllCompletedTests.matchFulfilled,
        (state, { payload }) => {
          state.allCompletedTests = payload?.data;
        },
      )
      .addMatcher(
        casServiceApi.endpoints.getCasQuestionsForExam.matchFulfilled,
        (state, { payload }) => {
          state.activeQuestionData = payload?.data;
        },
      )
      .addMatcher(
        casServiceApi.endpoints.getCasExamResult.matchFulfilled,
        (state, { payload }) => {
          state.activeResultData = payload?.data;
        },
      )
      .addMatcher(
        casServiceApi.endpoints.startMockTest.matchFulfilled,
        (state, { payload }) => {
          state.mockTestQuestions = payload?.data?.questions ?? [];
          state.mockTestId = payload?.data?.testId;
        },
      )
      .addMatcher(
        casServiceApi.endpoints.getTestDetails.matchFulfilled,
        (state, { payload }) => {
          state.mockTestResults = payload?.data;
        },
      );
  },
});

export const {
  updateCasActiveExamState,
  updateIsQuitAttempted,
  updateTestType,
  moveToNextMockQuestion,
  updateRecordingCount,
  resetCasState,
  updateCountDownActive,
  updateIsCameraTestingDone,
  updateRecordedAudio,
  updateRecordedMedia,
} = casPrepSlice.actions;

export const selectActiveCasExamState = (state: RootState) =>
  state.casPrepSlice.activeExamState;

export const selectAllPracticeTests = (state: RootState) =>
  state.casPrepSlice?.allPracticeTests;

export const selectAllCompletedTests = (state: RootState) =>
  state.casPrepSlice?.allCompletedTests;

export const selectActiveQuestionData = (state: RootState) =>
  state.casPrepSlice.activeQuestionData;

export const selectActiveResultData = (state: RootState) =>
  state.casPrepSlice.activeResultData;

export const selectIsQuitAttempted = (state: RootState) =>
  state.casPrepSlice.isQuitAttempted;

export const selectCurrentMockTestQuestion = (state: RootState) => {
  if (state.casPrepSlice.testType !== `MOCK`) return null;
  return (
    state.casPrepSlice.mockTestQuestions?.[
      state.casPrepSlice.mockTestCurrentQuestionIndex
    ] ?? null
  );
};

export const isRecordAgainButtonDisabled = (state: RootState) => {
  if (state.casPrepSlice.isCountdownActive === true) return true;
  return state.casPrepSlice.testType === `MOCK`
    ? MAX_RECORDING_ATTEMPTS -
        (state.casPrepSlice?.mockTestCurrentQuestionRecordingCount ??
          MAX_RECORDING_ATTEMPTS) <=
        0
    : false;
};

export const selectTestType = (state: RootState) =>
  state.casPrepSlice?.testType;

export const selectIsMockLastQuestion = (state: RootState) => {
  if (state.casPrepSlice.testType !== `MOCK`) return false;
  return (
    (state.casPrepSlice.mockTestCurrentQuestionIndex ?? 0) >=
    (state.casPrepSlice.mockTestQuestions?.length ?? 0) - 1
  );
};

export const selectCasData = (state: RootState) => state.casPrepSlice;

export const selectIsCountdownActive = (state: RootState) =>
  state.casPrepSlice.isCountdownActive;

export const selectActiveMockTestQuestion = (state: RootState) =>
  state.casPrepSlice.mockTestQuestions?.[
    state.casPrepSlice.mockTestCurrentQuestionIndex
  ];

export const selectIsCameraTestingDone = (state: RootState) =>
  state.casPrepSlice.isCameraTestingDone;

export const selectRecordedAudio = (state: RootState) =>
  state.casPrepSlice.recordedAudio;

export const selectRecordedMedia = (state: RootState) =>
  state.casPrepSlice.recordedMedia;

export default casPrepSlice.reducer;
