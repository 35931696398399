import React, { Ref } from 'react';

import { TextGroupAlert } from '@leapfinance/geebee-component-library';
import { type CustomContentProps, SnackbarContent } from 'notistack';

type SuccessSnackBarProps = CustomContentProps;

const SuccessSnackBar = React.forwardRef(
  (props: SuccessSnackBarProps, ref: Ref<HTMLDivElement>) => {
    const { message } = props;
    return (
      <SnackbarContent ref={ref} {...{ ...props, id: String(props.id) }}>
        <TextGroupAlert title={message} variant="success" />
      </SnackbarContent>
    );
  },
);

SuccessSnackBar.displayName = `SuccessSnackBar`;
export default SuccessSnackBar;
