import React, { useEffect, useState } from 'react';

import { Button, GBTypography } from '@leapfinance/geebee-component-library';
import CloseIcon from '@mui/icons-material/Close';
import { useRouter } from 'next/router';

import { useAppDispatch, useAppSelector } from '@/app/hooks';
import {
  isUKCommissionsAwarenessBannerEnabled,
  refreshBannerState,
  selectUserDetails,
} from '@/features/user/userSlice';
import { trackClick } from '@/segment/eventTracker';
import { WidgetFormat, WidgetName } from '@/types/segmentEvents';
import {
  GBLocalStorageUtil,
  localStorageKeys,
} from '@/utils/GBLocalStorageUtil';

const routesWhereToShow = [
  `/summary-dashboard`,
  `/course-finder`,
  `/applications`,
  `/students`,
  `/profile/[studentId]`,
];

function UKCommissionsAwarenessBanner() {
  const cspProfileData = useAppSelector((state) => selectUserDetails(state));
  const isBannerEnabled: boolean = useAppSelector((state) =>
    isUKCommissionsAwarenessBannerEnabled(state),
  );
  const router = useRouter();
  const isCounsellor = cspProfileData?.permissions?.length === 0;
  const dispatch = useAppDispatch();

  const [responseType, setResponseType] = useState<'initial' | 'yes' | 'no'>(
    `initial`,
  );
  const [shouldShowBanner, setShouldShowBanner] = useState(false);

  useEffect(() => {
    if (!router?.isReady) return;

    setShouldShowBanner(
      isBannerEnabled &&
        routesWhereToShow.includes(router?.pathname?.toString()),
    );
  }, [isBannerEnabled, router]);

  useEffect(() => {
    let timer: NodeJS.Timeout;
    if (responseType !== `initial`) {
      timer = setTimeout(() => {
        setShouldShowBanner(false);
        GBLocalStorageUtil.handleSetLocalStorageData(
          localStorageKeys.UK_COMMISSIONS_AWARENESS_BANNER,
          responseType,
        );
        dispatch(refreshBannerState());
      }, 6000);
    }

    return () => {
      if (timer) clearTimeout(timer);
    };
  }, [responseType, dispatch]);

  const handleCTAClick = (action: 'yes' | 'no') => {
    setResponseType(action);

    trackClick({
      widgetName: WidgetName.UK_COMMISSIONS_AWARENESS,
      widgetFormat: WidgetFormat.BANNER,
      contentFormat: `Button`,
      contentName: `uk_commissions_awareness_banner`,
      restInfo: {
        response: action,
        region: cspProfileData?.cspZone,
        type: isCounsellor ? `Counsellor` : `Admin`,
      },
    });
  };

  const renderBannerContent = () => {
    switch (responseType) {
      case `yes`:
        return (
          <>
            <div className="flex items-center gap-2">
              <div className="w-5 h-5">
                <img
                  src={`/svgs/blue-tick.svg`}
                  alt="success"
                  className="w-full h-full object-contain"
                />
              </div>
              <GBTypography
                variant="sh2Semibold"
                className="text-gbTokens-primary-500"
              >
                Thanks for your response, Wish you a good intake!
              </GBTypography>
            </div>
            <button
              className="text-gbTokens-primary-500"
              onClick={() => {
                setShouldShowBanner(false);
                GBLocalStorageUtil.handleSetLocalStorageData(
                  localStorageKeys.UK_COMMISSIONS_AWARENESS_BANNER,
                  responseType,
                );
                dispatch(refreshBannerState());
              }}
            >
              <CloseIcon color="primary" />
            </button>
          </>
        );
      case `no`:
        return (
          <>
            <div className="flex items-center gap-2">
              <div className="w-5 h-5">
                <img
                  src={`/svgs/thumbs-up.svg`}
                  alt="info"
                  className="w-full h-full object-contain"
                />
              </div>
              <GBTypography
                variant="sh2Semibold"
                className="text-gbTokens-primary-500"
              >
                Thanks for your response, team GeeBee will reach out to you
                soon. Meanwhile, check your email to see our latest servicing
                brochure
              </GBTypography>
            </div>
            <button
              className="text-gbTokens-primary-500"
              onClick={() => {
                setShouldShowBanner(false);
                GBLocalStorageUtil.handleSetLocalStorageData(
                  localStorageKeys.UK_COMMISSIONS_AWARENESS_BANNER,
                  responseType,
                );
                dispatch(refreshBannerState());
              }}
            >
              <CloseIcon color="primary" />
            </button>
          </>
        );
      default:
        return (
          <>
            <div className="flex items-center gap-2">
              <div className="w-5 h-5">
                <img
                  src={`/svgs/idea.svg`}
                  alt="idea"
                  className="w-full h-full object-contain"
                />
              </div>
              <GBTypography
                variant="sh2Semibold"
                className="text-gbTokens-primary-500"
              >
                {isCounsellor
                  ? `We are giving UK offers fastest in Industry today`
                  : `Our UK Commissions are Updated and partners are making as high as 3.5Lac per enrollment. Are you aware of it?`}
              </GBTypography>
            </div>
            <div className="flex items-center gap-3">
              <Button variant="secondary" onClick={() => handleCTAClick(`no`)}>
                No, I want to know more
              </Button>
              <Button
                variant="tertiary"
                className="text-gbTokens-primary-500"
                onClick={() => handleCTAClick(`yes`)}
              >
                {isCounsellor
                  ? `Yes, I already know about GeeBee servicing in UK`
                  : `Yes, I already know`}
              </Button>
            </div>
          </>
        );
    }
  };

  return shouldShowBanner ? (
    <div
      className={`px-6 py-3 w-full flex items-center justify-between bg-gbTokens-primary-100 ${
        router?.pathname?.toString() === `/profile/[studentId]`
          ? `fixed z-[500]`
          : ``
      } `}
    >
      {renderBannerContent()}
    </div>
  ) : null;
}

export default UKCommissionsAwarenessBanner;
