export const checkCameraAndMicPermissions = async (): Promise<
  { camera: PermissionState; mic: PermissionState } | undefined
> => {
  try {
    const cameraPermission = await navigator.permissions.query({
      name: `camera` as PermissionName,
    });

    const micPermission = await navigator.permissions.query({
      name: `microphone` as PermissionName,
    });

    return { camera: cameraPermission.state, mic: micPermission.state };
  } catch (error) {
    console.error(`Error checking camera permission:`, error);
    return undefined;
  }
};

export const createAudioThresholdChecker = (
  audioThreshold: number,
  maxBelowThresholdDuration: number,
  audioCallback: (isAudioUsable: boolean) => void,
) => {
  let belowThresholdCounter = 0; // Counter to keep track of how long the audio is below the threshold

  return function checkAudioThreshold(average: number) {
    if (average < audioThreshold) {
      belowThresholdCounter++;
      if (belowThresholdCounter > maxBelowThresholdDuration) {
        audioCallback(false);
      } else {
        audioCallback(true);
      }
    } else {
      belowThresholdCounter = 0;
      audioCallback(true);
    }
  };
};

export const audioMimeTypes = [`audio/webm`, `audio/mp4`];
export const videoMimeTypes = [`video/webm`, `video/mp4`];

export const CasMediaTrackConstraints: MediaTrackConstraintSet = {
  echoCancellation: true, // Reduces background echo for clearer audio
  noiseSuppression: true, // Filters out background noise
  autoGainControl: false, // You may want to disable this for better control over gain
  sampleRate: 16000, // Set to 16 kHz (Whisper performs well with 16kHz or 44.1 kHz sample rates)
  channelCount: 1, // Mono audio, as Whisper is optimized for mono input
  deviceId: undefined, // Optionally specify a device ID if using a specific microphone
};
