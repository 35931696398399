import React, { Ref } from 'react';

import { TextGroupAlert } from '@leapfinance/geebee-component-library';
import { type CustomContentProps, SnackbarContent } from 'notistack';

type WarningSnackBarProps = CustomContentProps;

const WarningSnackBar = React.forwardRef(
  (props: WarningSnackBarProps, ref: Ref<HTMLDivElement>) => {
    const { message } = props;
    return (
      <SnackbarContent ref={ref} {...{ ...props, id: String(props.id) }}>
        <TextGroupAlert title={message} variant="warning" />
      </SnackbarContent>
    );
  },
);

WarningSnackBar.displayName = `WarningSnackBar`;
export default WarningSnackBar;
