export const PRE_LOGIN_ACCESS_PAGES = [
  `/forgotpwd`,
  `/resetpwd`,
  `/login`,
  `/student-login`,
  `/ielts`,
];

export const openApis = [
  `/api/auth`,
  `/download/courses`,
  `/v1/csps/onboarding`,
  `/cf/source-countries`,
  `/shared/values`,
  `/meeting-tracker/open-meeting/search`,
  `/v1/onboarding-form/csp-details`,
  `/shared/countries`,
  `/cas-mock-interview`,
  `/documents/pre-signed-url-no-auth`,
];
