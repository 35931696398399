import { useEffect } from 'react';

import { isFalsy } from '@leapfinance/frontend-commons';
import Head from 'next/head';
import Script from 'next/script';

import { useAppSelector } from '@/app/hooks';
import { selectUserAuthId } from '@/features/auth/authSlice';

const HeadScripts = ({ children }: { children: React.ReactNode }) => {
  const apiUrl = process.env.AUTH_SERVER;
  const isDev = apiUrl === `https://staging-auth.leapscholar.com`;

  const userAuthId = useAppSelector(selectUserAuthId);
  useEffect(() => {
    try {
      if (!isFalsy(userAuthId) && (window as any)?.clarity) {
        (window as any)?.clarity?.(
          `set`,
          `unisetuUserId`,
          `${userAuthId || `NA`}`,
        );
      }
    } catch (err) {
      console.log(`error loggin userid in clarity`, err);
    }
  }, [userAuthId]);

  return (
    <>
      <Head>
        {isDev ? <meta name="robots" content="noindex, nofollow" /> : null}
        <link rel="icon" href="/GBSign.svg" />
        <title>CSP Portal</title>
        <link rel="preconnect" href="https://fonts.googleapis.com" />
        <link
          rel="preconnect"
          href="https://fonts.gstatic.com"
          crossOrigin="true"
        />
        {/* eslint-disable-next-line @next/next/no-page-custom-font */}
        <link
          href="https://fonts.googleapis.com/css2?family=Noto+Sans:ital,wght@0,100..900;1,100..900&family=Poppins:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap"
          rel="stylesheet"
        />
      </Head>
      {userAuthId &&
        String(process.env.NEXT_PUBLIC_CLARITY_TRACKING_KEY) !== `` && (
          <Script
            id="csp-tracker-clarity"
            strategy="afterInteractive"
            dangerouslySetInnerHTML={{
              __html: `
                (function(c,l,a,r,i,t,y){
                  c[a]=c[a]||function(){(c[a].q=c[a].q||[]).push(arguments)};
                  t=l.createElement(r);t.async=1;t.src="https://www.clarity.ms/tag/"+i;
                  y=l.getElementsByTagName(r)[0];y.parentNode.insertBefore(t,y);
                  clarity("set", "unisetuUserId", "${userAuthId ?? ``}");
              })(window, document, "clarity", "script", "${
                process.env.NEXT_PUBLIC_CLARITY_TRACKING_KEY
              }");`,
            }}
          />
        )}
      <Script
        id="_webengage_script_tag"
        type="text/javascript"
        dangerouslySetInnerHTML={{
          __html: `var webengage;!function(w,e,b,n,g){function o(e,t){e[t[t.length-1]]=function(){r.__queue.push([t.join("."),
arguments])}}var i,s,r=w[b],z=" ",l="init options track screen onReady".split(z),a="feedback survey notification".split(z),c="options render clear abort".split(z),p="Open Close Submit Complete View Click".split(z),u="identify login logout setAttribute".split(z);if(!r||!r.__v){for(w[b]=r={__queue:[],__v:"6.0",user:{}},i=0;i < l.length;i++)o(r,[l[i]]);for(i=0;i < a.length;i++){for(r[a[i]]={},s=0;s < c.length;s++)o(r[a[i]],[a[i],c[s]]);for(s=0;s < p.length;s++)o(r[a[i]],[a[i],"on"+p[s]])}for(i=0;i < u.length;i++)o(r.user,["user",u[i]]);setTimeout(function(){var f=e.createElement("script"),d=e.getElementById("_webengage_script_tag");f.type="text/javascript",f.async=!0,f.src=("https:"==e.location.protocol?"https://ssl.widgets.webengage.com":"http://cdn.widgets.webengage.com")+"/js/webengage-min-v-6.0.js",d.parentNode.insertBefore(f,d)})}}(window,document,"webengage");webengage.init(${process.env.WEB_ENGAGE_KEY});`,
        }}
      />
      {![`/course-finder`, `/manage-student-registration-form`].includes(
        window?.location?.pathname,
      ) ? (
        <Script
          id="csp-freshchat"
          strategy="afterInteractive"
          dangerouslySetInnerHTML={{
            __html: `
                (function (d, w, c) { if(!d.getElementById("spd-busns-spt")) { var n = d.getElementsByTagName('script')[0], s = d.createElement('script'); var loaded = false; s.id = "spd-busns-spt"; s.async = "async"; s.setAttribute("data-self-init", "false"); s.setAttribute("data-init-type", "opt"); s.src = 'https://cdn.in-freshbots.ai/assets/share/js/freshbots.min.js'; s.setAttribute("data-client", "4d04581fdc6f456c40c0bf86980a6a9e973aa349"); s.setAttribute("data-bot-hash", "${process.env.NEXT_PUBLIC_FRESHCHAT_BOT_ID}"); s.setAttribute("data-env", "prod"); s.setAttribute("data-region", "in"); if (c) { s.onreadystatechange = s.onload = function () { if (!loaded) { c(); } loaded = true; }; } n.parentNode.insertBefore(s, n); } }) (document, window, function () { Freshbots.initiateWidget({ autoInitChat: false, getClientParams: function () { return ; } }, function(successResponse) { }, function(errorResponse) { }); });
  `,
          }}
        />
      ) : null}
      {children}
    </>
  );
};
export default HeadScripts;
