import { createApi } from '@reduxjs/toolkit/query/react';

import { axiosBaseQuery } from '../axios/axiosBaseQuery';
import { BaseResponse, transformResponse } from '../utils';

export const logoutBaseApi = createApi({
  reducerPath: `logoutBaseApi`,
  baseQuery: axiosBaseQuery({ baseUrl: `/api/auth/logout` }),
  endpoints: (builder) => ({
    logout: builder.query<BaseResponse<any>, void>({
      query: () => `/`,
      transformResponse: transformResponse,
    }),
  }),
});

export const { useLogoutQuery, useLazyLogoutQuery } = logoutBaseApi;
