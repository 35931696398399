import { isFalsy } from '@leapfinance/frontend-commons';
import axios, { AxiosError, isAxiosError } from 'axios';
import axiosRetry from 'axios-retry';

import { store } from '@/app/store';
import { updateAuthentication } from '@/features/auth/authSlice';
import { BaseResponse } from '@/types/Services';
import snackbar from '@/utils/snackbar';

import { triggerLogout } from '../logoutUtil';
import { BFFApiError } from '../utils';

import { openApis } from './constants';
import { fixApiPath } from './utils';

const controller = new AbortController();

const axiosInstance = axios.create({ signal: controller.signal });

axiosInstance.interceptors.request.use(
  (config) => {
    const isAuthenticated = store?.getState()?.authSlice?.isAuthenticated;
    const isOpenApi = openApis.some((url) =>
      config?.url?.includes(fixApiPath(url)),
    );

    if (isOpenApi) return config;

    if (!isAuthenticated) {
      return Promise.reject(
        new AxiosError(`User not authenticated`, `AUTH_ERROR`),
      );
    }
    return config;
  },
  (error) => Promise.reject(error),
);

axiosInstance.interceptors.response.use(
  (config) => {
    if (config?.status == 200 && config?.data?.success == false) {
      snackbar.toast(config?.data?.message, { variant: `error` });
    }
    return config;
  },
  async (error: AxiosError<BaseResponse<any>> | Error) => {
    let message = `An unexpected error occurred. Please try again.`;

    if (isAxiosError(error) && error?.response) {
      const { status } = error.response;
      switch (status) {
        case 400:
          snackbar.toast(`Invalid request. Please check your input`, {
            variant: `error`,
          });
          break;
        case 401:
          const isAuthenticated = store?.getState()?.authSlice?.isAuthenticated;
          const isExpired = store.getState()?.authSlice?.isExpired;

          if (isAuthenticated && !isFalsy(isExpired)) {
            snackbar.toast(
              `Your session has expired. Redirecting to login page`,
              {
                variant: `error`,
              },
            );

            store.dispatch(updateAuthentication(false));
            triggerLogout(true);
          }

          break;
        case 403:
          snackbar.toast(`You do not have permission to perform this action.`, {
            variant: `error`,
          });
          break;
        case 404:
          snackbar.toast(`The requested resource could not be found.`, {
            variant: `error`,
          });
          break;
        case 500:
          snackbar.toast(
            `Something went wrong on our end. Please try again later.`,
            { variant: `error` },
          );
          break;
        case 503:
          snackbar.toast(
            `The service is temporarily down. Please try again later.`,
            { variant: `error` },
          );
          break;
        default:
          snackbar.toast(error.response?.data?.message ?? message, {
            variant: `error`,
          });
      }
    }
    return Promise.reject(error);
  },
);

axiosRetry(axiosInstance, {
  retries: 2,
  retryCondition: (error) => {
    if (!error?.response?.status) return false;
    return (
      (error as AxiosError<BFFApiError<any>>).response?.data?.errorCode ===
        `ECONNABORTED` ||
      (error as AxiosError<BFFApiError<any>>).response?.data?.errorCode ===
        `ECONNREFUSED` ||
      (error?.response?.status >= 500 && error?.response?.status < 600)
    );
  },
  retryDelay: (retryCount) => {
    return retryCount * 1000;
  },
  onRetry: () => {
    snackbar.toast(`Retrying request...`, { variant: `info` });
  },
});

export default axiosInstance;
