export enum ApiStatus {
  SUCCESS = `Success`,
  FAILED = `Failed`,
}

export enum LoginStatus {
  SUCCESS = `Success`,
  FAILED = `Failed`,
}

export enum LoginType {
  SIGN_IN = `Sign IN`,
  SIGN_UP = `Sign Up`,
  RESET_PASSWORD = `Reset Password`,
  FORGOT_PASSWORD = `Forgot Password`,
}

export enum CardType {
  ACADEMIC = `Academic`,
  WORK_EXPERIENCE = `Work experience`,
  EXAM = `Exam`,
  APPLICATION = `Application`,
  DOCUMENT_STUDENT = `Student Document`,
  DOCUMENT_APPLICATION = `Application Document`,
}

export enum WidgetName {
  HEADER_NAV = `Header Nav`,
  ADD_NEW_STUDENT = `Add New Student`,
  ADD_NEW_APPLICATION = `Add New Application`,
  SEARCH_BAR = `Search Bar`,
  TABLE = `Table`,
  EMPTY_TABLE = `Empty Table`,
  STUDENT_PROFILE = `Student Profile`,
  APPLICATION_CARD = `Application Card`,
  UPDATE_MODAL = `Update Modal`,
  COMMENT = `Comment`,
  PARTNER_DOCUMENT = `Partner Document`,
  APPLICATION_STATUS = `Application Status`,
  NOTIFICATION_MODAL = `Notification Modal`,
  NOTIFICATION_LIST = `Notification List`,
  MOVE_TO_DRAFT = `Move to Draft`,
  ADD_SHORTLIST_MANUALLY = `Add Shortlist Manually`,
  STUDENT_ELIGIBILITY_MODAL = `Student Eligibility Modal`,
  ADVANCED_SEARCH = `Advanced Search`,
  REQUEST_SHORTLIST = `Request Shortlist`,
  PRIORITY_MODAL = `Priority Modal`,
  SHORTLIST_TAB = `Shortlist Tab`,
  REQUEST_SHORTLIST_MODAL = `Request Shortlist Modal`,
  IMMEDIATE_PAYMENT = `Immediate Payment`,
  APPLICATION_FILTER = `Application Filter`,
  STUDENT_FILTER = `Student Filter`,
  'Actions Required' = `Actions Required`,
  'Pending At GeeBee' = `Action Pending`,
  'Others' = `Others`,
  PRIMARY_ACTION = `Primary Action`,
  PRIORITY = `Priority`,
  TOP_CTA = `Top CTA`,
  RESULT_NAV = `Result Nav`,
  RESULT_CARD = `Result Card`,
  FILTER_SIDEBAR = `Filter Sidebar`,
  PAGINATION = `Pagination`,
  NO_RESULT = `No Result`,
  SEARCH_CARD = `Search Card`,
  DOCUMENT_CHIP = `Document Chip`,
  LEADS_AT_RISK = `Leads at Risk`,
  LEADS_AT_RISK_BANNER = `Leads at Risk Banner`,
  STUDENT_CARDS = `Student Cards`,
  PREFERRED_INSTITUTE = `Preferred Institute`,
  FILTER_SECTION = `Filter Section`,
  MORE_ACTIONS = `More Actions`,
  ACTION_CARD = `Action Card`,
  STUDENT_PROFILE_INCOMPLETE = `Student Profile Incomplete`,
  WHY_NON_ELIGIBLE = `Why Non Eligible`,
  COURSE_ELIGIBILITY_CHECK = `Course Eligibility Check`,
  CHECK_ELIGIBLE_COURSES_ON_CF = `Check Eligible Courses On CF`,
  UPLOAD_DOCUMENT = `Upload Document`,
  STUDENT_ELIGIBILITY_TAG = `Student Eligibility Tag`,
  REQUIRED_DOCUMENT_MODAL = `Required Document Modal`,
  SIMILAR_COURSES_CARD = `Similar Courses Card`,
  COURSE_NAME_DROPDOWN = `Course Name Dropdown`,
  COURSE_DROPDOWN = `Course Dropdown`,
  UNIVERSITY_DROPDOWN = `University Dropdown`,
  SKIP_ON_NEXT_STEP = `Skip on Next Step`,
  STUDENT_PROFILE_NEXT_STEP_CARD = `Student Profile Next Step Card`,
  UNIVERSITY_COURSE_TAB = `University/Course Tab`,
  UNIVERSITY_NAME = `University Name`,
  COURSE_COUNT = `Course Count`,
  DEGREE_TAB_UNIVERISTY_PAGE = `Degree tab in university page`,
  UNIVERSITY_WEBSITE = `University Website`,
  UNIVERSITY_RAISE_APPLICATION = `Raise application univeristy page`,
  CONDITIONAL_ADMIT_CARD = `Conditional Admit Card`,
  EDIT_CONDITIONS = `Edit Conditions`,
  GTE_UPLOAD_DOCUMENTS = `GTE Upload Documents`,
  GTE_CARD = `GTE Card`,
  GTE_LETTER = `GTE Letter`,
  GTE_DOCUMENTS = `GTE Documents`,
  NEWSLETTER_ICON = `newsletter icon`,
  NEW_DASHBOARD_PERFORMANCE_SECTION_TITLE = `Your Performance`,
  NEW_DASHBOARD_UPCOMING_EVENTS = `Upcoming Events`,
  NEW_DASHBOARD_UNIVERSITY_UPDATES = `University Updates`,
  NEW_DASHBOARD_QUICK_LINKS = `Quick Links`,
  NEW_DASHBOARD_RM = `Relationship Manager`,
  NEW_DASHBOARD_COUNTRY_EXPERT = `Country Experts`,
  COURSE_LINK_PDF = `Course Link PDF`,
  SELECT_PROGRAM_TOUR = `Select program tour`,
  COURSE_DETAILS = `Course Details`,
  COURSE_DETAILS_SHORTLIST_MODAL = `Shortlist Modal`,
  COURSE_DETAILS_HEADER = `Course Details Header`,
  COURSE_DETAILS_FOOTER = `Course Details Footer`,
  NO_RESULT_SECTION = `No Result Section`,
  DEFERRAL = 'Deferral',
  REGISTRATION_FORM_CONFIGURATION = 'Registration Form Configuration',
  DELETE_CONFIRMATION = 'Delete Confirmation',
  REGISTRATION_LANDING_PAGE = 'Registration Landing Page',
  EXIT_FORM = 'Exit Form',
  LEAD_SOURCE = 'Lead Source',
  REGISTRATION_FORM = 'Registration Form',
  DISABLE_FORM = 'Disable Form',
  MARKETING_BANNER = `Marketing Banner`,
  TOP_FILTER = 'Top Filter',
  COURSE_DETAIL_PAGE = `Course Detail Page`,
  SHORTLIST_HEADER = `Shortlist Header`,
  SHORTLIST_REQUEST = `Shortlist Request`,
  CF_SHORTLIST = `CF Shortlist`,
  SIMILAR_COURSES = `Similar Courses`,
  COURSE_RESULT_SECTION = `Course Results Section`,
  SHORLIST_REQUEST = `Shortlist Request`,
  INFORMATION_HUB_NAVIGATION = 'Information Hub Navigation',
  PARTNERED_INSTITUTES = 'Partnered Institutes',
  STUDENT_PROFILE_NAVIGATION = `Student Profile Navigation`,
  STUDENT_ENGAGEMENT_ENABLE = `Student Engagement Enable`,
  CSP_LEADERBOARD = 'Csp Leaderboard',
  GTM_BANNER = `GTM_BANNER`,
  GTM_MODAL = `GTM_MODAL`,
  IELTS_LIVE_DEMO_POPUP = 'IELTS Live Demo Popup',
  DUOLINGO_VOUCHER_POPUP = 'Duolingo Voucher Popup',
  FOCUSED_INSTITUTE_CARD = 'Focused Institute Card',
  VIEW_COURSES = 'View Courses',
  UNIVERSITY_TAB_CLICK = 'University Tab Clicked',
  FOCUSED_RAISE_APPLICATION = 'Focused University Raise Application',
  FOCUSED_CREATE_APPLICATION = 'Focused University Create Application',
  COVENTRY_ADMISSION_DRIVE_MODAL = 'Coventry Admission Drive Modal',
  INTERNATIONAL_GETAWAY_MODAL = 'International Getaway Drive Modal',
  CAS_MOCK_INTERVIEW = 'CAS Mock Interview',
  CAS_MOCK_NAVIGATION = 'CAS Mock Navigation',
  LEARNING_RESOURCES = 'Learning Resources',
  LEARNING_RESOURCES_HEADER = 'Learning Resources Header',
  PRACTICE_QUESTION_CARD = 'Practice Question Card',
  ENABLE_PERMISSIONS_CAMERA = 'Enable Permissions Camera',
  TEST_INSTRUCTIONS = 'Test Instructions',
  QUIT_PRACTICE = 'Quit Practice',
  PRACTICE_PLAYGROUND = 'Practice Playground',
  PRACTICE_PERFORMANCE_REVIEW = 'Practice Performance Review',
  ATTEMPTED_QUESTION_CARD = 'Attempted Question Card',
  FOCUSED_INSTITUTE_SIMILAR_COURSES = `Focused Institute Similar Courses`,
  APPLICATION_PAGE_SIMILAR_COURSES = `Application Page Similar Courses`,
  UNIVERSITY_QUICK_FILTERS = `University Quick Filters`,
  AUTO_SHORTLIST_RECOMMENDED_COURSES = `Auto Shortlist Recommended Courses`,
  MOCK_TEST_HEADER = `Mock Test Header`,
  COMPLETED_TEST_CARD = `Completed Test Card`,
  UK_COMMISSIONS_AWARENESS = `UK Commissions Awareness`,
  UK_MID_INTAKE_PAYMENT_DRIVE = `UK Mid Intake Payment Drive`,
}

export enum WidgetFormat {
  BANNER = `Banner`,
  MODAL = `Modal`,
  NAVIGATION = `Navigation`,
  CARD = `Card`,
  SECTION = `Section`,
  DROPDOWN = `Dropdown`,
  SIDEBAR = `Sidebar`,
  PAGINATION = `Pagination`,
  CHIP = `Chip`,
  ACTION_CARD = `Action Card`,
  MORE_ACTIONS_BUTTON = `More Actions Button`,
  WARNING_MESSAGE = `Warning Message`,
  BUTTON = `Button`,
  DOCUMENT_UPLOAD_CHIP = `Document Upload Chip`,
  TAG = `Tag`,
  COURSE_CARD = `Course Card`,
  TAB = `Tab`,
  UNIVERSITY_CARD = `University Card`,
  DOCUMENT_CHIP = `Document Chip`,
  ICON = `Icon`,
  TOUR = `Tour Selection`,
  LINK = `Link`,
  FORM = 'Form',
  HEADER = `Header`,
}

export enum PageNames {
  APPLICATION_DASHBOARD = `Application Dashboard`,
  STUDENT_DASHBOARD = `Student Dashboard`,
  COURSE_FINDER = `Course Finder`,
  STUDENT_ELIGIBILITY_MODAL = `Student Eligibility Modal`,
  STUDENT_PAGE = `Student Page`,
  APPLICATION_PAGE = `Application Page`,
  CHANGE_PASSWORD_PAGE = `Change Password`,
  FORGOT_PASSWORD_PAGE = `Forgot Password`,
  LOGIN_PAGE_PAGE = `Login Page`,
  NOTIFICATION = `Notification Page`,
  SUMMARY_DASHBOARD = `Summary Dashboard`,
  LEAD_AT_RISK_BANNER = `Lead at Risk banner`,
  MORE_ACTION_SIDE_PANE = `More Action Side Pane`,
  APPLICATION_CREATION_MODAL = `Application Creation Modal`,
  COLLEGE_ELIGIBILITY_WARNING = `College Eligibility Warning on App Modal`,
  COURSE_FINDER_RESULTS_PAGE = `Course Finder Results Page`,
  SIMILAR_COURSES_PAGE = `Similar Courses Page`,
  CF_SHORTLIST_MODAL = `CF Shortlist Modal`,
  STUDENT_PROFILE_PAGE = `Student Profile Page`,
  UNIVERSITY_PAGE = `University Page`,
  NEWSLETTER = `Newsletter`,
  COURSE_DETAILS_PAGE = `Course Details Page`,
  COURSE_DETAILS = `Course Details`,
  PDF_SHORTLIST = `PDF Shortlist`,
  KNOWLEDGE_LIBRARY = `Knowledge Library`,
  SHORTLIST_PAGE = `Student Shortlist Page`,
  STUDENT_ENGAGEMENT = `Student Engagement`,
  IELTS_LIVE_DEMO_POPUP = 'IELTS Live Demo Popup',
  DUOLINGO_VOUCHER_POPUP = 'Duolingo Voucher Popup',
  FOCUSED_INSTITUTE = 'Focused Institute',
  FOCUSED_UNIVERSITY_COURSES_MODAL = `Focused University Courses Modal`,
  RAISE_APPLICATION_FROM_FOCUSED_UNIVERSITY_COURSES = 'Raise Application From Focused University Courses',
  COVENTRY_ADMISSION_DRIVE_MODAL = 'Coventry Admission Drive Modal',
  INTERNATIONAL_GETAWAY_MODAL = 'International Getaway Drive Modal',
  CAS_MOCK_INTERVIEW = 'CAS Mock Interview',
  CAS_MOCK_INTERVIEW_LR = 'CAS Mock Interview - LR',
  CAS_MOCK_INTERVIEW_PRACTICE = 'CAS Mock Interview - Practice',
  UNIVERSITY_QUICK_FILTERS = `University Quick Filters`,
  AUTO_SHORTLIST_RECOMMENDED_COURSES = `Auto Shortlist Recommended Courses`,
  CAS_MOCK_INTERVIEW_TEST = `CAS Mock Interview - Test`,
  UK_MID_INTAKE_PAYMENT_DRIVE = `UK Mid Intake Payment Drive`,
}

export type TrackPageProperties = {
  pageName: string;
  pageUrl?: string;
  browser?: string;
  platform?: string;
  cspId?: string | number;
  userId?: string | number;
  studentId?: string | number;
  leadStage?: string;
  source?: string;
  domain?: string;
  restInfo?: Record<string, any>;
};

export type TrackAuthenticationProperties = {
  pageName?: string;
  authenticationType?: `Email`;
  loginType?: LoginType;
  loginStatus?: LoginStatus;
};

// export type TrackApplicationStage = {
//   applicationId: string;
//   applicationDate: string;
//   applicationStage: string;
//   collegeId: number;
//   courseId: number;
//   studentId: number;
//   cspId: number;
//   country: string;
//   intake: string;
// };

export type TrackCard = {
  studentId: number;
  cspId: number;
  pageName: string;
  cardType: CardType;
  cardSubType: string;
};

export type TrackDocumentUpload = {
  pageName: string;
  pageUrl: string;
  studentId: string;
  cspId: number;
  userId: number;
  widgetName: string;
  widgetFormat: string;
  contentName: string;
  contentFormat: string;
  documentCategory: string;
  documentName: string;
  documentSize: string;
  documentFormat: string;
  documentStatus: ApiStatus;
};

export type TrackClick = {
  pageName: string;
  pageUrl?: string;
  widgetName: WidgetName;
  widgetFormat: WidgetFormat;
  contentName: string;
  contentFormat: string;
  applicationId?: number;
  userId?: string | number;
  cspId?: string | number;
  studentName?: string;
  courseID?: string | number;
  courseCount?: string | number;
  studentEligibilityModal?: StudentEligibilityModal;
  eligibilityParameters?: any[];
  universityId?: number | string;
  nonEligibleParameters?: any[];
  nonEligibleCourseParameters?: string;
  nonEligibleStudentParameters?: string;
  studentId?: number | string;
  leadStage?: string;
  applicationStage?: string;
  source?: string;
  courseName?: string;
  courseCountry?: string;
  universityName?: string;
  pageNumber?: number;
  appliedFilterName?: Record<string, any>;
  category: string;
  meta1: any;
  meta2: any;
  cardName?: string;
  selectedCountry?: string;
  downloadFormat?: string;
  courseIds?: number[];
  totalCourses?: number;
  actionType?: string;
  requestNumber?: number;
  requestedBy?: string;
  requestId?: number;
  leadSource?: string;
  download_format?: 'PDF' | 'CSV';
  shortlistRequestID?: number;
  messageType: string;
  featureName: string;
  restInfo?: Record<string, any>;
};
export type StudentEligibilityModal = {
  preferredField?: string;
  highestQualification?: string;
  overallGrades12th?: string | number;
  ugGrades?: string | number;
  totalBacklogs?: string | number;
  totalYearsOfEducation?: string | number;
  totalWorkExperience?: string | number;
  courseRelevant?: string;
  workExRelevant?: string;
  englishExamStatus?: string;
  englishExamType?: any;
  englishExamScore?: string | number;
};

export type IdentifyUser = {
  email: string;
  partnerName: string;
  firstName: string;
  lastName: string;
  userName: string;
  userId: number;
  cspId: number;
};

export enum SegmentEventSource {
  CF = 'course-finder',
}
