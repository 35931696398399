import React, { Ref } from 'react';

import { TextGroupAlert } from '@leapfinance/geebee-component-library';
import { type CustomContentProps, SnackbarContent } from 'notistack';

type ErrorSnackBarProps = CustomContentProps;

const ErrorSnackBar = React.forwardRef(
  (props: ErrorSnackBarProps, ref: Ref<HTMLDivElement>) => {
    const { message } = props;
    return (
      <SnackbarContent ref={ref} {...{ ...props, id: String(props.id) }}>
        <TextGroupAlert title={message} variant="error" />
      </SnackbarContent>
    );
  },
);

ErrorSnackBar.displayName = `ErrorSnackBar`;
export default ErrorSnackBar;
